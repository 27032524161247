<template>
    <div />
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    methods: {
        // eslint-disable-next-line no-unused-vars
        mapDataToFormItems (_data) {
            return {
            }
        },
    },
}
</script>
