var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productLocalCustomerFormItem" },
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างลูกค้า" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("CustomerCreateForm", {
            on: { success: _vm.onSuccessCreateCustomer }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ลูกค้า"
          }
        },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "localStadiumCustomerId",
                    { rules: [{ required: true, message: "เลือกลูกค้า" }] }
                  ],
                  expression:
                    "[ 'localStadiumCustomerId' ,\n                           { rules:\n                               [\n                                   { required: true,\n                                     message: 'เลือกลูกค้า', } ] } ]"
                }
              ],
              staticStyle: { width: "240px" },
              attrs: {
                "show-search": "",
                placeholder: "เลือกลูกค้า",
                "option-filter-prop": "children",
                "filter-option": _vm.filterOption,
                loading: _vm.isLoadLocalStadiumCustomers
              },
              on: {
                change: _vm.onLocalStadiumCustomerChange,
                focus: _vm.loadLocalStadiumCustomers
              },
              scopedSlots: _vm._u([
                {
                  key: "dropdownRender",
                  fn: function(menu) {
                    return _c(
                      "div",
                      {},
                      [
                        _c("VNodes", { attrs: { vnodes: menu } }),
                        _c("a-divider", { staticStyle: { margin: "4px 0" } }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "4px 8px",
                              cursor: "pointer"
                            },
                            on: {
                              mousedown: function(e) {
                                return e.preventDefault()
                              },
                              click: _vm.openCreateForm
                            }
                          },
                          [
                            _c("a-icon", { attrs: { type: "plus" } }),
                            _vm._v(" เพิ่มลูกค้า\n                ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            },
            _vm._l(_vm.localCustomers, function(localStadiumCustomer) {
              return _c(
                "a-select-option",
                {
                  key: localStadiumCustomer.id,
                  attrs: { value: localStadiumCustomer.id }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(localStadiumCustomer.firstName) +
                      " " +
                      _vm._s(localStadiumCustomer.lastName) +
                      "\n            "
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canBuyMultiple,
              expression: "canBuyMultiple"
            }
          ],
          attrs: {
            label: "จำนวนคน",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "numberOfPeople",

                  {
                    initialValue: 1,
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'numberOfPeople',\n\n                  {\n                      initialValue: 1,\n                      rules:\n                          [ { required: true,\n                              message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ],
            attrs: { min: 1 }
          }),
          _vm._v(" คน\n    ")
        ],
        1
      ),
      _c("PeopleCreateAndUpdateFormItems", {
        attrs: {
          form: _vm.form,
          "action-type": "create",
          data: _vm.customerData,
          "is-require-last-name": false,
          "read-only": true,
          "enable-note-input": true,
          "enable-address-input": false
        }
      }),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "จ่ายเงินแล้ว"
          }
        },
        [
          _c("a-checkbox", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "isPaid",
                  {
                    initialValue: false,
                    valuePropName: "checked"
                  }
                ],
                expression:
                  "[ 'isPaid',{\n                initialValue: false,valuePropName: 'checked' } ]"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }