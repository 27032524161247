<template>
    <div>
        <VFormManageMember>
            <template #header>
                <TableMemberTypesActiveTxItem :tx-item="txItem" :product-id="productId" />
            </template>
            <template
                v-slot:operation="{ text: { text: { record } } }">
                <a-popconfirm
                    @confirm="() => onChangeMember(record.id)">
                    <template slot="title">
                        <h4>สิทธิ์ที่ใช้เดิมจะถูกแทนที่ </h4>
                        <p>
                            <strong>หมายเหตุ</strong>  <br>
                            * เป็นการเปลี่ยนแปลง  <strong>เฉพาะการซื้อครั้งนี้เท่านั้น  </strong>*
                        </p>
                    </template>
                    <a-button type="primary" @click.stop>
                        ใช้สิทธิ์นี้
                    </a-button>
                </a-popconfirm>
                <a-popconfirm
                    title="ยืนยันการลบ?"
                    @confirm="() => onDelete(record.id)">
                    <a
                        href="javascript:;"
                        class="table__operation"
                        @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VFormManageMember>
    </div>
</template>
<script>
import moment from 'moment'
import { rootActions } from '@/store/types'
import VFormManageMember from '@/components/VFormManageMember.vue'
import TableMemberTypesActiveTxItem from '@/components/TableMemberTypesActiveTxItem.vue'

export default {
    components: {
        TableMemberTypesActiveTxItem,
        VFormManageMember,
    },
    props: {
        productId: {
            type: String,
            required: true,
        },
        txItem: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            visibleDetailPage: false,
            visibleHistoryPage: false,
            selectedMemberTypeId: null,
            selectedMemberType: null,
            moment,
        }
    },
    methods: {
        getExpiredDate (startDate, expiredInDay) {
            // To Avoid modify original data
            const momentCopy = moment(startDate)
            // console.log(startDate)
            // console.log(expiredInDay)
            return momentCopy.add(expiredInDay, 'days')
        },
        getTextColumn () {
            return this.columns.filter((item) => item.scopedSlots.customRender !== 'color'
            && item.scopedSlots.customRender !== 'duration')
        },
        // eslint-disable-next-line no-unused-vars
        async onChangeMember (_memberTypeId) {
            this.currentMemberLoading = true
            const { productId } = this
            const oldTxItem = this.txItem
            const newTxItem = { ...oldTxItem, stadiumMemberIds: [_memberTypeId] }
            const res = await this.$store.dispatch(
                rootActions.UPDATE_PRODUCT_LOCAL_CUSTOMER,
                { boostId: productId, customer: newTxItem },
            )
            if (res.isSuccess()) {
                this.$emit('success', res.data)
            }
            this.currentMemberLoading = false
        },
        async onDelete (id) {
            const res = await this.$store.dispatch(rootActions.DELETE_MEMBER_TYPE, id)
            return res
        },
    },
}
</script>
<style lang="stylus">
.member-types__add-button
    margin-bottom: 16px
    float: right
.member-types__member-type-color
    height 32px
.member-types__table
    clear both
.manage-member-form__current-member-type-table
    margin-bottom 16px
    clear both
.manage-member-form__history-button
    margin-bottom: 16px
    float: right
.manage-member-form__remaining-days
    display inline-block
.table__operation
    margin-left:8px
</style>
