<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="สนาม">
            <a-select
                v-decorator="[ 'fieldId' ,
                               { rules:
                                   [
                                       { required: true,
                                         message: 'เลือกสนามที่ต้องการโปรโมท', } ] } ]"
                :disabled="disabled"
                show-search
                placeholder="เลือกสนาม"
                option-filter-prop="children"
                style="width: 240px;"
                :filter-option="filterOption"
                :loading="isLoadFields"
                @change="onFieldChange"
                @focus="loadFields">
                <a-select-option
                    v-for="field in fields"
                    :key="field.id"
                    :value="field.id">
                    {{ field.name }}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item
            v-if="isReserveTypeOnly"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภท">
            <a-radio-group
                v-decorator="
                    [ 'type',
                      {
                          initialValue: ProductType.RESERVE
                      }
                    ]"
                :disabled="type !== 'create' ">
                <a-radio :value="ProductType.RESERVE">
                    จองปกติ (ซื้อแล้วได้ทั้งสนาม)
                </a-radio>
            </a-radio-group>
        </a-form-item>
        <a-form-item
            v-else
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภทโปรโมชั่น">
            <a-radio-group
                v-decorator="
                    [ 'eventType',
                      {
                          initialValue: EventType.RESERVATION
                      }
                    ]"
                :disabled="type !== 'create' ">
                <a-radio :value="EventType.RESERVATION">
                    จองปกติ (ซื้อแล้วได้ทั้งสนาม)
                </a-radio>
                <a-radio :value="EventType.BUFFET">
                    บุฟเฟ่ (ซื้อเป็นรายคนร่วมกันเล่น)
                </a-radio>
                <a-radio :value="EventType.TRAINING">
                    คอร์สเรียน
                </a-radio>
                <a-radio :value="EventType.TOURNAMENT">
                    การแข่งขัน
                </a-radio>
            </a-radio-group>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="คำบรรยายเบื้องต้น">
            <a-textarea
                v-decorator="[ 'desc' ]"
                placeholder="เช่น ความพิเศษของโปรโมชั่น(เช่น 1เดือนมีครั้ง) , เงื่อนไขการใช้งาน"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <VFormItemRangeDateTime
            :data="data"
            :form="form"
            @startDateTimeChange="onStartDateTimeChange"
            @endDateTimeChange="onEndDateTimeChange" />
        <a-form-item
            v-if="estimatePrice"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <span slot="label">ราคาประเมิน &nbsp;
                <a-tooltip
                    title="คำนวนจาก ตั้งค่าสนาม->ราคา">
                    <a-icon type="question-circle-o" />
                </a-tooltip>&nbsp;
            </span>
            <a-input-number
                class="estimatePrice"
                :value="estimatePrice"
                :min="0"
                :disabled="true" /> บาท <strong>*เป็นราคาอ้างอิงจากตารางราคา ช่วยในการตัดสินใจตั้งราคา</strong>
            <br>
            <a-button
                type="primary"
                @click="copyPriceToClipboard">
                <a-icon type="copy" />
                คัดลอก
            </a-button>
        </a-form-item>
        <!-- Can't use v-if because it will create form item with wrong required prop
        and it can't update to correct value because this element is being detroy-->
        <VFormItemNumberWithBar
            v-show="canHaveMultiple()"
            name-prop="totalImportToStock"
            :form="form"
            :data="data"
            :min="1"
            label="จำนวนสินค้าทั้งหมด"
            :validator="validateMaxPeople"
            :required="canHaveMultiple()" />
        <a-form-item
            label="ช่องทางการขาย"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <div>
                <a-radio-group
                    v-decorator="[ 'isPrivate' ,
                                   {
                                       initialValue: false,
                                       rules:
                                           [
                                               { required: true,
                                                 message: 'เลือกช่องทางการขายด้วยค่ะ', } ] } ]"
                    button-style="solid">
                    <a-radio-button :value="false">
                        แอป Lenkila และ จองตรง
                    </a-radio-button>
                    <a-radio-button :value="true">
                        จองตรงเท่านั้น
                    </a-radio-button>
                </a-radio-group>
            </div>
        </a-form-item>
        <!-- <a-form-item
            label="เปิดขายในแอป"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-switch
                checked-children="เปิด"
                :checked="!form.getFieldValue(`isPrivate`)"
                @change="onIsPublicChange" />
        </a-form-item> -->
        <ProductVariantFormItems
            v-if="!form.getFieldValue(`isPrivate`)"
            :form="form"
            :data="data"
            :is-update-form="isUpdateForm" />
        <ProductLocalCustomersFormItems
            v-if=" !isUpdateForm && form.getFieldValue(`isPrivate`)"
            :form="form"
            :data="data"
            :max-people="form.getFieldValue(`totalImportToStock`) ? form.getFieldValue(`totalImportToStock`) : 1" />
    </div>
</template>

<script>
import VFormItemNumberWithBar from '@/components/VFormItemNumberWithBar.vue'
import ProductVariantFormItems from '@/components/ProductVariantFormItems.vue'
import VFormItemRangeDateTime from '@/components/VFormItemRangeDateTime.vue'
import ProductLocalCustomersFormItems from '@/components/ProductLocalCustomersFormItems.vue'
import { EventType, ProductType } from '@/utils/enum'
import { rootActions } from '@/store/types'
import FormItems from '@/mixins/FormItems'
import { copyTextToClipboard, isReserveType } from '@/utils/utils'
import { ApiField } from '@/api'

export default {
    components: {
        VFormItemRangeDateTime,
        ProductVariantFormItems,
        VFormItemNumberWithBar,
        ProductLocalCustomersFormItems,
    },
    mixins: [FormItems],
    props: {
        type: {
            type: String,
            default: '',
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
        isOfferMode: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            tooltipVisible: false,
            EventType,
            isLoadFields: false,
            numberOfJoinedPeople: null,
            ProductType,
            fieldId: null,
            start: null,
            end: null,
            estimatePrice: null,
        }
    },
    computed: {
        fields () {
            return this.$store.state.stadium.fields.filter(
                (field) => field.stadiumId === this.$store.state.stadium.id,
            )
        },
        isReserveTypeOnly () {
            if (this.isReserveType(this.data)) return true
            return this.isOfferMode
        },
        priceRules () {
            return this.$store.state.field.priceRules
        },
    },
    watch: {
        start () {
            this.calculateFieldPrice()
        },
        end () {
            this.calculateFieldPrice()
        },
        priceRules () {
            this.calculateFieldPrice()
        },
    },
    beforeMount () {
        this.loadFields()
    },
    methods: {
        async calculateFieldPrice () {
            if (!this.start || !this.end) {
                this.estimatePrice = null
                return
            }
            const res = await ApiField.getCalculateFieldPrice({
                fieldId: this.fieldId,
                startedOn: this.start.format(),
                endedOn: this.end.format(),
            })
            this.estimatePrice = res.isSuccess() ? res.data : null
        },
        isReserveType,
        canHaveMultiple () {
            // this.form.getFieldValue will not trigger any change when we use on computed value
            // so we need to use method instead.
            return this.form.getFieldValue('eventType') !== EventType.RESERVATION && this.form.getFieldValue('type') !== ProductType.RESERVE
        },
        async loadFields () {
            this.isLoadFields = true
            await this.$store.dispatch(rootActions.LOAD_FIELDS, { page: 1, size: 100 })
            this.isLoadFields = false
        },
        filterOption (input, option) {
            return (
                option.componentOptions.children[0]
                    .text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        mapDataToFormItems (data) {
            this.numberOfJoinedPeople = data.numberOfJoinedPeople
            const {
                fieldId, end, start, isPrivate,
            } = data
            this.onFieldChange(fieldId)
            this.onEndDateTimeChange(end)
            this.onStartDateTimeChange(start)
            return {
                isPrivate,
                fieldId: data.fieldId,
                desc: data.desc,
                eventType: data.eventType,
            }
        },
        validateMaxPeople (rule, value, callback) {
            if (value != null) {
                if (typeof value !== 'number') return callback('ต้องเป็นตัวเลขเท่านั้น')
                if (value <= 0) { return callback('จำกัดจำนวนผู้เล่น ต้องมากกว่า 0') }
                if (value < this.numberOfJoinedPeople) {
                    return callback(
                        'มีคนจองไปแล้ว'
                        + `${this.numberOfJoinedPeople}คน จำกัดจำนวนผู้เล่นจึงต้องมากกว่า `
                        + `${this.numberOfJoinedPeople}`,
                    )
                }
            }
            return callback()
        },
        onEndDateTimeChange (end) {
            this.end = end
        },
        onStartDateTimeChange (start) {
            this.start = start
        },
        onFieldChange (fieldId) {
            this.fieldId = fieldId
            this.$store.dispatch(rootActions.LOAD_FIELD_PRICE_RULES, this.fieldId)
        },
        async copyPriceToClipboard () {
            copyTextToClipboard(this.estimatePrice)
        },
    },
}
</script>
<style lang="stylus">
.estimatePrice.ant-input-number.ant-input-number-disabled
    color: black
</style>
