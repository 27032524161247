export default class ModelTimeSlot {
    constructor ({
        start,
        end,
        price,
    }) {
        Object.assign(this, {
            start,
            end,
            price,
        })
    }

    static fromTimeRange (
        start,
        end,
        slotDuration,
    ) {
        let next = start
        const slots = []
        while (next.isBefore(end)) {
            const _start = next
            const _end = next.clone().add(slotDuration, 'minutes')
            slots.push(new ModelTimeSlot({ start: _start, end: _end }))
            next = next.clone().add(slotDuration, 'minutes')
        }
        return slots
    }
}
