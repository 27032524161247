<template>
    <div>
        <a-modal
            v-model="visibleUpdateForm"
            title="สิทธิที่ใช้ในการซื้อ"
            width="50%"
            centered="centered"
            :footer="null"
            destroy-on-close>
            <ManageTxItemMemberForm
                :product-id="selectedEntityId"
                :tx-item="txItem"
                @success="onUpdateMemberSuccess" />
        </a-modal>
        <div
            v-if="model"
            class="customers__member-type"
            @click.stop="openUpdateForm(productId)">
            <a
                class="customers__member-type-action"
                href="javascript:;"
                @click.stop="openUpdateForm(productId)"><a-icon type="form" /></a>
            <div
                class="customers__member-type-color"
                :style="{ backgroundColor: model.color,maxWidth: '72px' }" />
            <span class="customers__member-type-name">  {{ model.name }} </span>
        </div>
        <div
            v-else
            :style="{ textAlign: 'center' }"
            class="customers__member-type"
            @click.stop="openUpdateForm(productId)">
            <a href="javascript:;">เพิ่ม</a>
        </div>
    </div>
</template>
<script>
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'
import ManageTxItemMemberForm from '@/components/ManageTxItemMemberForm.vue'

export default {
    components: {
        ManageTxItemMemberForm,
    },
    mixins: [MixinUpdateFormModal],
    props: {
        model: {
            type: Object,
            default: undefined,
        },
        productId: {
            type: String,
            required: true,
        },
        txItem: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onUpdateMemberSuccess (e) {
            // this.closeUpdateForm()
            this.$emit('change', e)
        },
    },
}
</script>
<style lang="stylus">
$membershipHeight = 32px

.customers__sync-button
    cursor: pointer

.customers__table
    clear: both

.customers
    .ant-calendar-picker
        width: 120px

.customers__customer-operations a
    margin-right 8px

.customers__top-bar
    margin-bottom 24px
.customers__member-type
    display inline-block
    > *
        display inline-block
        vertical-align middle
.customers__member-type-name
    margin-left  8px
    line-height $membershipHeight
.customers__member-type-color
    height $membershipHeight
    min-width $membershipHeight
    overflow hidden
    width auto
.customers__member-type-action
    float right
    line-height $membershipHeight
    margin-left  16px
.customers__member-type
    cursor: pointer
</style>
