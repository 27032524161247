var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-types" },
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "แก้ไข ประเภทสมาชิก",
            width: "50%",
            centered: "centered",
            footer: null,
            "destroy-on-close": ""
          },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("MemberTypeUpdateForm", {
            attrs: { id: _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c("VTable", {
        staticClass: "member-types__table",
        attrs: {
          "row-key": "id",
          bordered: "",
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          loading: _vm.loading,
          pagination: _vm.pagination,
          "custom-row": _vm.createClickToEditRow
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u(
          [
            {
              key: "color",
              fn: function(ref) {
                var text = ref.text
                return [
                  _c("div", {
                    staticClass: "member-types__member-type-color",
                    style: { backgroundColor: text }
                  })
                ]
              }
            },
            {
              key: "duration",
              fn: function(ref) {
                var text = ref.text
                var record = ref.record
                return [
                  _c("TableColMemberTypeExpiredInDay", {
                    attrs: { type: record.type, "expired-in-days": text }
                  })
                ]
              }
            },
            {
              key: "operation",
              fn: function(ref) {
                var record = ref.record
                return [
                  _vm.dataSource.length
                    ? _c(
                        "a-popconfirm",
                        {
                          attrs: { title: "ยืนยันการลบ?" },
                          on: {
                            confirm: function() {
                              return _vm.onConfirmDelete(record)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v("ลบ")]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              }
            },
            _vm._l(_vm.$scopedSlots, function(index, name) {
              return {
                key: name,
                fn: function(text) {
                  return [_vm._t(name, null, null, text)]
                }
              }
            })
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }