<template>
    <a-form
        class="customer-create-form"
        :form="form"
        layout="horizontal"
        @submit="handleSubmit">
        <StadiumBoostLocalCustomersCreateAndUpdateFormItems
            :form="form"
            action-type="create"
            :can-buy-multiple="canBuyMultiple"
            :data="customer" />
        <StadiumBoostLocalCustomersUpdateFormItems
            :form="form"
            :data="customer" />
        <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
            <a-button
                type="primary"
                html-type="submit"
                :loading="isProcessing">
                บันทึก
            </a-button>
        </a-form-item>
    </a-form>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import StadiumBoostLocalCustomersCreateAndUpdateFormItems from '@/components/StadiumBoostLocalCustomersCreateAndUpdateFormItems.vue'
import Form from '@/mixins/Form'
import StadiumBoostLocalCustomersUpdateFormItems from '@/components/StadiumBoostLocalCustomersUpdateFormItems.vue'

export default {
    components: {
        StadiumBoostLocalCustomersCreateAndUpdateFormItems,
        StadiumBoostLocalCustomersUpdateFormItems,
    },
    mixins: [Form],
    props: {
        boostId: {
            type: String,
            required: true,
        },
        customerId: {
            type: String,
            required: true,
        },
    },
    computed: {
        boost () {
            return this.$store.getters[Getters.products.GET_PRODUCT](this.boostId)
        },
        canBuyMultiple () {
            return this.boost.totalImportToStock > 1
        },
        customer () {
            return this.$store.getters[Getters.products.GET_PRODUCT](this.boostId)
                .localCustomers.find((item) => item.id === this.customerId)
        },
    },
    methods: {
        async submit (values) {
            const { boostId, customerId } = this
            const res = await this.$store.dispatch(
                rootActions.UPDATE_PRODUCT_LOCAL_CUSTOMER,
                { boostId, customer: { id: customerId, ...values } },
            )
            return res
        },
    },
}
</script>
