<template>
    <VPage
        :title="title"
        :borderless="borderless"
        :title-style="titleStyle">
        <a-modal
            v-model="visibleCreateForm"
            title="สร้าง ประเภทสมาชิก"
            width="50%"
            centered="centered"
            :footer="null"
            destroy-on-close>
            <MemberTypeCreateForm
                @success="onCreateEntityPagination" />
        </a-modal>
        <a-button
            type="primary"
            class="member-types__add-button"
            @click="openCreateForm">
            เพิ่ม
        </a-button>
        <SectionNew v-if="newEntities.length > 0">
            <MemberTypes
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                @delete="onDeleteEntityPagination">
                <template v-for="(index, name) in $scopedSlots" v-slot:[name]="text,record">
                    <slot
                        :name="name"
                        v-bind="{ text,record }" />
                </template>
            </MemberTypes>
        </SectionNew>
        <MemberTypes
            :data-source="entities"
            :loading="loading"
            :custom-row="createClickToEditRow"
            @delete="onDeleteEntityPagination">
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="text,record">
                <slot
                    :name="name"
                    v-bind="{ text,record }" />
            </template>
        </MemberTypes>
    </VPage>
</template>
<script>
import { rootActions } from '@/store/types'
import VPage from '@/components/VPage.vue'
import MemberTypes from '@/components/MemberTypes.vue'
import MemberTypeCreateForm from '@/components/MemberTypeCreateForm.vue'
import SectionNew from '@/components/SectionNew.vue'
import EntitiesPagination from '@/mixins/EntitiesPagination'

export default {
    components: {
        VPage,
        MemberTypes,
        MemberTypeCreateForm,
        SectionNew,
    },
    mixins: [EntitiesPagination],
    props: {
        borderless: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'ระบบสมาชิก',
        },
        titleStyle: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        getAllEntities () {
            return this.$store.state.customers.memberTypes
        },
        async fetchEntityPagination () {
            const { page, size } = this
            return this.$store.dispatch(rootActions.LOAD_MEMBER_TYPES, { page, size })
        },
        async delete (id) {
            const res = await this.$store.dispatch(rootActions.DELETE_MEMBER_TYPE, id)
            return res
        },
    },
}
</script>
