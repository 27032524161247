<template>
    <VPage title="เปลี่ยนรหัสผ่าน">
        <PasswordChangeForm />
    </VPage>
</template>

<script>
import { rootActions } from '@/store/types'
import PasswordChangeForm from '@/components/PasswordChangeForm.vue'
import VPage from '@/components/VPage.vue'
import { isStadiumUser } from '@/utils/enum'

export default {
    components: {
        PasswordChangeForm,
        VPage,
    },
    computed: {
        generalInfo () {
            return this.$store.state.auth.me
        },
    },
    async created () {
        await this.$store.dispatch(rootActions.GET_ME)
    },
    methods: {
        async submit (values) {
            const action = isStadiumUser(this.generalInfo)
                ? rootActions.UPDATE_EMPLOYEE : rootActions.UPDATE_MANAGER
            const res = await this.$store.dispatch(
                action,
                { ...this.generalInfo, ...values },
            )
            return res
        },
    },
}
</script>
