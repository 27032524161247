<template>
    <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="ทั่วไป">
            <VForm
                v-slot="{ form,data }"
                :data="field"
                :on-submit-async="submit"
                @success="(value)=>this.$emit('success',value)">
                <StadiumFieldCreateAndUpdateFormItems
                    :form="form"
                    :data="data" />
            </VForm>
        </a-tab-pane>
        <a-tab-pane
            key="2"
            tab="ตารางราคา">
            <FieldPrice :field-id="fieldId" />
        </a-tab-pane>
    </a-tabs>
</template>
<script>
import StadiumFieldCreateAndUpdateFormItems from '@/components/StadiumFieldCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import { rootActions, Getters } from '@/store/types'
import FieldPrice from '@/components/FieldPrice.vue'

export default {
    components: {
        StadiumFieldCreateAndUpdateFormItems,
        VForm,
        FieldPrice,
    },
    props: {
        fieldId: {
            type: String,
            required: true,
        },
    },
    computed: {
        field () {
            return this.$store.getters[Getters.stadium.GET_FIELD](this.fieldId)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_STADIUM_FIELD, { id: this.field.id, ...values },
            )
            return res
        },
    },
}
</script>
