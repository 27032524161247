var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        title: "Member Type Detail",
        width: "50%",
        centered: "centered",
        footer: null,
        "destroy-on-close": ""
      },
      on: { change: _vm.onChange }
    },
    [
      _vm.data !== null
        ? _c("MemberTypeDetails", { attrs: { data: _vm.data } })
        : _vm._e(),
      _c(
        "a-form-item",
        {
          style: { marginBottom: 0 },
          attrs: { "wrapper-col": { span: 12, offset: 4 } }
        },
        [
          _c(
            "a-button",
            {
              staticClass: "member-types-details__close-button",
              attrs: { type: "primary" },
              on: { click: _vm.close }
            },
            [_vm._v("\n            Close\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }