<template>
    <div class="member-types">
        <a-modal
            v-model="visibleUpdateForm"
            title="แก้ไข ประเภทสมาชิก"
            width="50%"
            centered="centered"
            :footer="null"
            destroy-on-close>
            <MemberTypeUpdateForm
                :id="selectedEntityId"
                @success="closeUpdateForm" />
        </a-modal>
        <VTable
            class="member-types__table"
            row-key="id"
            bordered=""
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            :pagination="pagination"
            :custom-row="createClickToEditRow"
            @change="handleTableChange">
            <template
                v-slot:color="{ text }">
                <div class="member-types__member-type-color" :style="{ backgroundColor: text }" />
            </template>
            <template
                v-slot:duration="{ text, record }">
                <TableColMemberTypeExpiredInDay :type="record.type" :expired-in-days="text" />
            </template>
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="() => onConfirmDelete(record)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="text">
                <slot
                    :name="name"
                    v-bind="text" />
            </template>
        </Vtable>
    </div>
</template>
<script>
import TableColMemberTypeExpiredInDay from '@/components/TableColMemberTypeExpiredInDay.vue'
import MemberTypeUpdateForm from '@/components/MemberTypeUpdateForm.vue'
import MixinTable from '@/mixins/MixinTable'
import VTable from '@/components/VTable.vue'

export default {
    components: {
        TableColMemberTypeExpiredInDay,
        MemberTypeUpdateForm,
        VTable,
    },
    mixins: [MixinTable],
    data () {
        return {
            columns: [
                {
                    title: 'สี',
                    dataIndex: 'color',
                    width: '72px',
                    scopedSlots: { customRender: 'color' },
                },
                {
                    title: 'ชื่อ',
                    dataIndex: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                // {
                //     title: 'Description',
                //     dataIndex: 'description',
                //     scopedSlots: { customRender: 'description' },
                // },
                // {
                //     title: 'Type',
                //     dataIndex: 'type',
                //     scopedSlots: { customRender: 'type' },
                // },
                // {
                //     title: 'Price',
                //     dataIndex: 'price',
                //     scopedSlots: { customRender: 'price' },
                // },
                // {
                //     title: 'Duration',
                //     dataIndex: 'expiredInDay',
                //     width: '140px',
                //     scopedSlots: { customRender: 'duration' },
                // },
                {
                    title: 'การจัดการ',
                    dataIndex: 'operation',
                    width: '20%',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
        }
    },
}
</script>
<style lang="stylus">
.member-types__add-button
    margin-bottom: 16px
    float: right
.member-types__member-type-color
    height 32px
.member-types__table
    clear both
.member-type__duration
    text-align center
</style>
