var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        { attrs: { label: "สร้างรหัสผ่านใหม่" } },
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "password",
                    {
                      rules: [
                        {
                          required: true,
                          message: "กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่"
                        }
                      ]
                    }
                  ],
                  expression:
                    "[\n                'password',\n                { rules: [ { required: true,\n                             message: 'กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่' } ] } ]"
                }
              ],
              attrs: { type: "password", placeholder: "รหัสผ่านใหม่" }
            },
            [
              _c("a-icon", {
                staticStyle: { color: "rgba(0,0,0,.25)" },
                attrs: { slot: "prefix", type: "lock" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: "ยืนยัน รหัสผ่านใหม่" } },
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "confirmPassword",
                    {
                      rules: [
                        {
                          required: true,
                          message:
                            "กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่ อีกครั้ง"
                        },
                        { validator: _vm.compareToFirstPassword }
                      ]
                    }
                  ],
                  expression:
                    "[\n                'confirmPassword',\n                { rules: [\n                    { required: true,\n                      message: 'กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่ อีกครั้ง' },\n                    { validator: compareToFirstPassword, }\n                ] } ]"
                }
              ],
              attrs: { type: "password", placeholder: "รหัสผ่านใหม่" }
            },
            [
              _c("a-icon", {
                staticStyle: { color: "rgba(0,0,0,.25)" },
                attrs: { slot: "prefix", type: "lock" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }