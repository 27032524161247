var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        { attrs: { "label-col": { span: 4 }, "wrapper-col": { span: 12 } } },
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v("สี  \n            "),
              _c(
                "a-tooltip",
                {
                  attrs: {
                    title:
                      "เลือกสีที่เราทำไว้แล้ว หรือ จะใส่รหัสสีเองก็ได้เป็น HEX code"
                  }
                },
                [_c("a-icon", { attrs: { type: "question-circle-o" } })],
                1
              ),
              _vm._v(" \n        ")
            ],
            1
          ),
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "color",
                  {
                    rules: [
                      {
                        required: true,
                        message:
                          "กรุณาใส่ค่าสี ในรูปแบบ HEX code(เช่น #000000)."
                      }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'color',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ค่าสี ในรูปแบบ HEX code(เช่น #000000).', } ] } ]"
              }
            ],
            staticClass: "member-type-create-and-update-form__color-input",
            attrs: { placeholder: "เช่น #000000" },
            on: {
              change: function(e) {
                return (_vm.previewColor = e.target.value)
              }
            }
          }),
          _c("div", {
            staticClass: "member-type-create-and-update-form__color-preview",
            style: { backgroundColor: _vm.previewColor }
          }),
          _c(
            "div",
            {
              staticClass: "member-type-create-and-update-form__color-presets"
            },
            [
              _vm._v("\n            สีตั้งต้น:\n            "),
              _vm._l(_vm.presetColors, function(color) {
                return _c("div", {
                  key: color,
                  style: { backgroundColor: color },
                  on: {
                    click: function($event) {
                      return _vm.setColor(color)
                    }
                  }
                })
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อ"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าในช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'name',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ค่าในช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }