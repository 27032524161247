<template>
    <ProductLocalCustomerCreateForm
        :max-people="boost.totalImportToStock"
        @success="submit" />
</template>
<script>
import { Getters, rootActions } from '@/store/types'
import ProductLocalCustomerCreateForm from '@/components/ProductLocalCustomerCreateForm.vue'
import Form from '@/mixins/Form'
import VApiResponse from '@/utils/VApiResponse'

export default {
    components: {
        ProductLocalCustomerCreateForm,
    },
    mixins: [Form],
    props: {
        boostId: {
            type: String,
            required: true,
        },
    },
    computed: {
        boost () {
            return this.$store.getters[Getters.products.GET_PRODUCT](this.boostId)
        },
    },
    methods: {
        async submit (values) {
            const { boostId } = this
            const res = await this.$store.dispatch(
                rootActions.ADD_PRODUCT_LOCAL_CUSTOMER, {
                    boostId,
                    customer: values,
                    numberOfPeople: values.numberOfPeople,
                },
            )
            if (res instanceof VApiResponse) {
                if (res.isSuccess()) {
                    this.$emit('success', res.data)
                } else {
                    this.$emit('error', res.data)
                }
            } else {
                this.$emit('success', res)
            }
            return res
        },
    },
}
</script>
