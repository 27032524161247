<template>
    <VPage title="ข้อมูลบัญชีผู้ใช้">
        <VForm
            v-slot="{ form,data }"
            :data="generalInfo"
            :on-submit-async="submit"
            @success="(value)=>this.$emit('success',value)">
            <UserCreateAndUpdateFormItems
                :form="form"
                :data="data"
                :is-create-form="false" />
            <PeopleCreateAndUpdateFormItems :form="form" :data="data" />
        </VForm>
    </VPage>
</template>

<script>
import { rootActions } from '@/store/types'
import UserCreateAndUpdateFormItems from '@/components/UserCreateAndUpdateFormItems.vue'
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import VPage from '@/components/VPage.vue'
import { isStadiumUser } from '@/utils/enum'

export default {
    components: {
        UserCreateAndUpdateFormItems,
        PeopleCreateAndUpdateFormItems,
        VForm,
        VPage,
    },
    computed: {
        generalInfo () {
            return this.$store.state.auth.me
        },
    },
    async created () {
        await this.$store.dispatch(rootActions.GET_ME)
    },
    methods: {
        async submit (values) {
            const action = isStadiumUser(this.generalInfo)
                ? rootActions.UPDATE_EMPLOYEE : rootActions.UPDATE_MANAGER
            const res = await this.$store.dispatch(
                action,
                { ...this.generalInfo, ...values },
            )
            return res
        },
    },
}
</script>
