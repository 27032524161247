<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อสนาม">
            <a-input
                v-decorator="
                    [ 'name',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]" />
        </a-form-item>
        <VFormItemCoverPhoto :form="form" :data="data" />
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="คำบรรยายเบื้องต้น">
            <a-textarea
                v-decorator="[ 'description' ]"
                placeholder="ใส่คำอธิบายเกี่ยวกับสนาม เช่น สนามฟุตบอลขนาดเล็ก อยู่ในร่ม"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภทกีฬา">
            <a-select
                v-decorator="[ 'sportId',
                               { rules:
                                   [
                                       { required: true,
                                         message: 'กรุณาเลือกกีฬาด้วยค่ะ', }
                                   ] } ]"
                show-search
                placeholder="เลือกประเภทกีฬา"
                option-filter-prop="children"
                style="width: 240px;"
                :filter-option="filterOption">
                <a-select-option
                    v-for="sport in sports"
                    :key="sport.id"
                    :value="sport.id">
                    {{ sport.displayName }}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="แบ่งขายได้">
            <a-checkbox
                v-decorator="[ 'dividable',{
                    initialValue: false,valuePropName: 'checked' } ]" />
        </a-form-item>
    </div>
</template>

<script>
import VFormItemCoverPhoto from '@/components/VFormItemCoverPhoto.vue'
import FormItems from '@/mixins/FormItems'
import { rootActions } from '@/store/types'

export default {
    components: {
        // VFormItemOpenAndCloseTime,
        VFormItemCoverPhoto,
    },
    mixins: [FormItems],
    data () {
        return {
            tooltipVisible: false,
            // sports: Object.keys(Sport).map((sport) => toTitleCase(sport)),
            marks: {
                1: '1',
                10: '10',
                20: '20',
                30: '30',
                40: '40',
            },
        }
    },
    computed: {
        sports () {
            return this.$store.state.stadium.sports
        },
    },
    created () {
        this.$store.dispatch(rootActions.LOAD_SPORTS)
    },
    methods: {
        filterOption (input, option) {
            return (
                option.componentOptions.children[0]
                    .text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        mapDataToFormItems (data) {
            return {
                name: data.name,
                description: data.description,
                sportId: data.sportId,
                minMaxPeople: data.minMaxPeople,
                dividable: data.dividable,
            }
        },
    },
}
</script>
