var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VFormManageMember", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("TableMemberTypesActiveTxItem", {
                  attrs: { "tx-item": _vm.txItem, "product-id": _vm.productId }
                })
              ]
            },
            proxy: true
          },
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.text.text.record
              return [
                _c(
                  "a-popconfirm",
                  {
                    on: {
                      confirm: function() {
                        return _vm.onChangeMember(record.id)
                      }
                    }
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("h4", [_vm._v("สิทธิ์ที่ใช้เดิมจะถูกแทนที่ ")]),
                      _c("p", [
                        _c("strong", [_vm._v("หมายเหตุ")]),
                        _c("br"),
                        _vm._v(
                          "\n                        * เป็นการเปลี่ยนแปลง  "
                        ),
                        _c("strong", [
                          _vm._v("เฉพาะการซื้อครั้งนี้เท่านั้น  ")
                        ]),
                        _vm._v("*\n                    ")
                      ])
                    ]),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    ใช้สิทธิ์นี้\n                "
                        )
                      ]
                    )
                  ],
                  2
                ),
                _c(
                  "a-popconfirm",
                  {
                    attrs: { title: "ยืนยันการลบ?" },
                    on: {
                      confirm: function() {
                        return _vm.onDelete(record.id)
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "table__operation",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [_vm._v("ลบ")]
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }