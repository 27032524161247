var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VForm", {
    staticClass: "reset-password-form",
    attrs: { "on-submit-async": _vm.handleSubmit },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var form = ref.form
          return [
            _vm.responseMsg
              ? _c("a-alert", {
                  staticClass: "reset-password-form__info-msg",
                  attrs: {
                    type: _vm.messageType,
                    message: _vm.responseMsg,
                    banner: "banner"
                  }
                })
              : _vm._e(),
            _c(
              "a-form-item",
              { attrs: { label: "รหัสผ่านปัจจุบัน" } },
              [
                _c(
                  "a-input",
                  {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "currentPassword",
                          {
                            rules: [
                              {
                                required: true,
                                message: "กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                'currentPassword',\n                { rules: [ { required: true,\n                             message: 'กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่' } ] } ]"
                      }
                    ],
                    attrs: { type: "password", placeholder: "รหัสผ่านปัจจุบัน" }
                  },
                  [
                    _c("a-icon", {
                      staticStyle: { color: "rgba(0,0,0,.25)" },
                      attrs: { slot: "prefix", type: "lock" },
                      slot: "prefix"
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("PasswordResetFormItem", { attrs: { form: form } })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }