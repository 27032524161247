<template>
    <a-modal
        :visible="visible"
        title="Member Type Detail"
        width="50%"
        centered="centered"
        :footer="null"
        destroy-on-close
        @change="onChange">
        <MemberTypeDetails
            v-if="data !== null"
            :data="data" />
        <a-form-item
            :wrapper-col="{ span: 12, offset: 4 }"
            :style="{ marginBottom: 0 }">
            <a-button
                class="member-types-details__close-button"
                type="primary"
                @click="close">
                Close
            </a-button>
        </a-form-item>
    </a-modal>
</template>
<script>
import MemberTypeDetails from '@/components/MemberTypeDetails.vue'

export default {
    components: {
        MemberTypeDetails,
    },
    model: {
        prop: 'open',
        event: 'change',
    },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        visible () {
            return this.open
        },
    },
    methods: {
        close () {
            this.$emit('change', false)
            this.$emit('close')
        },
        onChange (value) {
            this.$emit('change', value)
            if (!value) {
                this.$emit('close')
            }
        },
    },
}
</script>
