let nextMockId = -1

function assignMockId () {
    nextMockId -= 1
    return nextMockId + 1
}
export default {
    methods: {
        applyNewRules (rules, newRules) {
            return [rules, ...newRules].reduce((result, newRule) => {
                const mergedRules = []
                let isNewRule = true
                result.forEach((rule) => {
                    if (this.isRangeDateTimeEqual(rule, newRule)) {
                        isNewRule = false
                        if (newRule.price === rule.price) {
                            mergedRules.push(rule)
                            return
                        }
                        mergedRules.push({
                            ...rule, id: assignMockId(), price: newRule.price, fromServer: false,
                        })
                        return
                    }

                    if (this.isRangeTimeIsNotOverlapse(rule, newRule)) {
                        mergedRules.push(rule)
                        return
                    }

                    // |----------New Rule----------|
                    // |---------- |---Rule--|--------|
                    if (newRule.rangeTime[0].isSameOrBefore(rule.rangeTime[0])
                    && newRule.rangeTime[1].isSameOrAfter(rule.rangeTime[1])) {
                        return
                    }
                    // |-----New Rule------|---------------------|
                    // or
                    // |-----------|-----New Rule--|-------------|
                    // |---------- |---------Rule---------|--------|
                    if (newRule.rangeTime[0].isSameOrBefore(rule.rangeTime[0])
                    && newRule.rangeTime[1].isBefore(rule.rangeTime[1])) {
                        mergedRules.push({
                            ...rule,
                            id: assignMockId(),
                            rangeTime: [newRule.rangeTime[1], rule.rangeTime[1]],
                            fromServer: false,
                        })
                        return
                    }
                    // |------------------|-------New Rule-------|
                    // or
                    // |---------------|-----New Rule---|--------|
                    // |---------- |---------Rule---------|--------|
                    if (newRule.rangeTime[1].isSameOrAfter(rule.rangeTime[1])
                     && newRule.rangeTime[0].isBefore(rule.rangeTime[1])) {
                        mergedRules.push({
                            ...rule,
                            id: assignMockId(),
                            rangeTime: [rule.rangeTime[0], newRule.rangeTime[0]],
                            fromServer: false,
                        })
                        return
                    }
                    // |--------|----New Rule-------|-------------------|
                    // |- |---------------Rule------------------|--------|
                    mergedRules.push({
                        ...rule,
                        id: assignMockId(),
                        rangeTime: [rule.rangeTime[0], newRule.rangeTime[0]],
                    })
                    mergedRules.push({
                        ...rule,
                        id: assignMockId(),
                        rangeTime: [newRule.rangeTime[1], rule.rangeTime[1]],
                        fromServer: false,
                    })
                })

                if (isNewRule) {
                    const newRuleData = { ...newRule, id: assignMockId() }
                    mergedRules.push(newRuleData)
                }

                return mergedRules
            })
        },
        hasOverrideRules (oldRules, newRules) {
            return !this.checkSubset(newRules, oldRules)
        },
        checkSubset  (parentArray, subsetArray) {
            return subsetArray.every((el) => parentArray.includes(el))
        },
        isRangeDateTimeEqual (rule1, rule2) {
            const isTimeEqualCase1 = rule2.rangeTime && rule1.rangeTime
                        && rule2.rangeTime.length === 2 && rule1.rangeTime.length === 2
                        && rule2.rangeTime[0].isSame(rule1.rangeTime[0])
                        && rule2.rangeTime[1].isSame(rule1.rangeTime[1])
            const isTimeEqualCase2 = !rule2.rangeTime && !rule1.rangeTime
            const isTimeEqual = (isTimeEqualCase1 || isTimeEqualCase2)
            return rule2.weekDay === rule1.weekDay && isTimeEqual
        },
        isRangeTimeIsNotOverlapse (rule1, rule2) {
            const case1 = rule2.weekDay !== rule1.weekDay
            const case2 = (!rule2.rangeTime && rule1.rangeTime)
                    || (rule2.rangeTime && !rule1.rangeTime)
            const case3 = (rule2.rangeTime && rule1.rangeTime)
                        && (
                            rule2.rangeTime[1].isSameOrBefore(rule1.rangeTime[0])
                                || rule2.rangeTime[0].isSameOrAfter(rule1.rangeTime[1])
                                || (
                                    rule2.rangeTime[0].isSame(rule1.rangeTime[0])
                                    && rule2.rangeTime[1].isSame(rule1.rangeTime[1])
                                )
                        )
            return case1 || case2 || case3
        },
    },

}
