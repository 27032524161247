var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MemberTypeDetailsModal", {
        attrs: { data: _vm.selectedMemberType },
        on: {
          close: function($event) {
            _vm.selectedMemberType = null
          }
        },
        model: {
          value: _vm.visibleDetailPage,
          callback: function($$v) {
            _vm.visibleDetailPage = $$v
          },
          expression: "visibleDetailPage"
        }
      }),
      _vm._t("header"),
      _c("br"),
      _c(
        "PageMemberTypes",
        {
          attrs: {
            borderless: true,
            title: "ประเภทสมาชิกในระบบ",
            "title-style": { fontSize: "18px" }
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function(index, name) {
                return {
                  key: name,
                  fn: function(text, record) {
                    return [
                      name !== "header"
                        ? _vm._t(name, null, null, {
                            text: text,
                            record: record
                          })
                        : _vm._e()
                    ]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        [
          _c(
            "a-popconfirm",
            {
              attrs: { title: "ยืนยันการลบ?" },
              on: {
                confirm: function() {
                  return _vm.onDelete(_vm.record.id)
                }
              }
            },
            [
              _c(
                "a",
                {
                  staticClass: "table__operation",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [_vm._v("ลบ")]
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }