<template>
    <div class="table-col-member-type-expired-in-day">
        <span style="white-space: nowrap">
            {{ prettyFormatForexpiredInDays }}
        </span>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        expiredInDays: {
            type: Number,
            default: null,
        },
    },
    computed: {
        prettyFormatForexpiredInDays () {
            const { type, expiredInDays } = this
            if (type === 'monthly') {
                return `${expiredInDays / 30} Months (${expiredInDays} Days)`
            }
            if (type === 'yearly') {
                return `${expiredInDays / 365} Years (${expiredInDays} Days)`
            }
            if (type === 'permanant') {
                return 'ꝏ'
            }
            return `${expiredInDays} Days`
        },
    },
}
</script>
<style lang="stylus">
.table-col-member-type-expired-in-day
    text-align center
</style>
