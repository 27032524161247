<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <span slot="label">สี &nbsp;
                <a-tooltip
                    title="เลือกสีที่เราทำไว้แล้ว หรือ จะใส่รหัสสีเองก็ได้เป็น HEX code">
                    <a-icon type="question-circle-o" />
                </a-tooltip>&nbsp;
            </span>
            <a-input
                v-decorator="
                    [ 'color',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าสี ในรูปแบบ HEX code(เช่น #000000).', } ] } ]"
                class="member-type-create-and-update-form__color-input"
                placeholder="เช่น #000000"
                @change="(e)=>previewColor = e.target.value" />
            <div
                class="member-type-create-and-update-form__color-preview"
                :style="{ backgroundColor: previewColor }" />
            <div class="member-type-create-and-update-form__color-presets">
                สีตั้งต้น:
                <div
                    v-for="color in presetColors"
                    :key="color"
                    :style="{ backgroundColor: color }"
                    @click="setColor(color)" />
            </div>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อ">
            <a-input
                v-decorator="
                    [ 'name',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าในช่องนี้ด้วยค่ะ', } ] } ]" />
        </a-form-item>
        <!-- <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Description">
            <a-textarea
                v-decorator="[ 'description' ]"
                placeholder="Enter description of your field."
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Type">
            <a-radio-group
                v-decorator="
                    [ 'type',
                      { rules:
                            [ { required: true,
                                message: 'Please choose member type.', } ]
                        ,initialValue: 'daily' } ]"
                @change="onTypeChange">
                <a-radio value="daily">
                    Day
                </a-radio>
                <a-radio value="monthly">
                    Monthly
                </a-radio>
                <a-radio value="yearly">
                    Yearly
                </a-radio>
                <a-radio value="permanant">
                    Permanant
                </a-radio>
            </a-radio-group>
        </a-form-item>
        <a-form-item
            v-show="form.getFieldValue('type') === 'yearly'"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Year Amount">
            <a-input-number
                v-decorator="
                    [ 'expiredInYear' ]"
                :min="1"
                @change="onYearAmountChange" />
        </a-form-item>
        <a-form-item
            v-show="form.getFieldValue('type') === 'monthly'"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Month Amount">
            <a-input-number
                v-decorator="
                    [ 'expiredInMonth' ]"
                :min="1"
                @change="onMonthAmountChange" />
        </a-form-item>
        <a-form-item
            v-show="form.getFieldValue('type') !== 'permanant'"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Day Amount">
            <a-input-number
                v-decorator="
                    [ 'expiredInDay' ,{
                        rules:
                            [ { required: true,
                                message: 'Please set member duration in day unit', } ]
                        ,initialValue: 15 } ]"
                :disabled="form.getFieldValue('type') && form.getFieldValue('type') !== 'daily'"
                :min="1" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Price">
            <a-input-number
                v-decorator="
                    [ 'price',
                      { rules:
                          [
                              { required: true,
                                message: 'Please set a price', } ] } ]"
                :min="0"
                :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
        </a-form-item> -->

    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    data () {
        return {
            previewColor: '',
            presetColors: ['#000', '#ddd', '#F7931E'],
        }
    },
    methods: {
        mapDataToFormItems  (data) {
            this.previewColor = data.color
            const schema = {
                color: data.color,
                name: data.name,
                description: data.description,
                type: data.type,
                price: data.price,
                ...this.mapExpiredDateToForm(data),
            }
            return schema
        },
        mapExpiredDateToForm (data) {
            let schema = {}
            const { expiredInDay, type } = data
            if (type === 'monthly') {
                schema = {
                    expiredInMonth: expiredInDay / 30,
                    expiredInDay: 30,
                }
            } else if (type === 'yearly') {
                schema = {
                    expiredInYear: expiredInDay / 365,
                    expiredInDay: 365,
                }
            } else if (type === 'permanant') {
                schema = {
                    expiredInDay: 36500,
                }
            }
            return schema
        },
        setColor (color) {
            this.form.setFieldsValue({
                color,
            })
            this.previewColor = color
        },
        onMonthAmountChange (month) {
            this.form.setFieldsValue({
                expiredInDay: month * 30,
            })
        },
        onYearAmountChange (year) {
            this.form.setFieldsValue({
                expiredInDay: year * 365,
            })
        },
        onTypeChange (e) {
            const type = e.target.value
            if (type === 'monthly') {
                this.form.setFieldsValue({
                    expiredInMonth: 1,
                    expiredInDay: 30,
                })
            } else if (type === 'yearly') {
                this.form.setFieldsValue({
                    expiredInYear: 1,
                    expiredInDay: 365,
                })
            } else if (type === 'permanant') {
                this.form.setFieldsValue({
                    expiredInDay: 36500,
                })
            }
        },
    },
}
</script>
<style lang="stylus">
.member-type-create-and-update-form__color-input
    width auto
.member-type-create-and-update-form__color-preview
    height 32px
    width 64px
    vertical-align middle
    display inline-block
.member-type-create-and-update-form__color-presets
    height 32px
    > *
        height 100%
        width  32px
        margin-right 8px
        cursor pointer
        display inline-block
</style>
