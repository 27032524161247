<template>
    <VForm
        v-slot="{ form }"
        class="customer-create-form"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <PeopleCreateAndUpdateFormItems
            :form="form"
            action-type="create"
            :enable-address-input="false"
            :enable-note-input="true" />
    </VForm>
</template>
<script>
import { rootActions } from '@/store/types'
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import Form from '@/mixins/Form'
import VForm from '@/components/VForm.vue'

export default {
    components: {
        PeopleCreateAndUpdateFormItems,
        VForm,
    },
    mixins: [Form],
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_CUSTOMER, { ...values },
            )
            return res
        },
    },
}
</script>
