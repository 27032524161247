var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        {
          staticClass: "product-customers__action-button",
          attrs: { type: "primary" },
          on: { click: _vm.copyCustomersToClipboard }
        },
        [
          _c("a-icon", { attrs: { type: "copy" } }),
          _vm._v("\n        คัดลอกรายชื่อ\n    ")
        ],
        1
      ),
      _c("TableTxItems", {
        attrs: {
          shink: true,
          "data-source": _vm.dataSource,
          loading: _vm.loading,
          pagination: _vm.pagination,
          "is-active": _vm.isActive
        },
        on: { cancel: _vm.onCancel, change: _vm.handleTableChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }