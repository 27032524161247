<template>
    <a-form
        class="customer-create-form"
        :form="form"
        layout="horizontal"
        @submit="handleSubmit">
        <StadiumBoostLocalCustomersCreateAndUpdateFormItems
            :can-buy-multiple="maxPeople > 1"
            :form="form"
            action-type="create"
            :data="data" />
        <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
            <a-button
                type="primary"
                html-type="submit"
                :loading="isProcessing">
                บันทึก
            </a-button>
        </a-form-item>
    </a-form>
</template>
<script>
import StadiumBoostLocalCustomersCreateAndUpdateFormItems from '@/components/StadiumBoostLocalCustomersCreateAndUpdateFormItems.vue'
import Form from '@/mixins/Form'

export default {
    components: {
        StadiumBoostLocalCustomersCreateAndUpdateFormItems,
    },
    mixins: [Form],
    props: {
        maxPeople: {
            type: Number,
            required: true,
        },
    },
    methods: {
        async submit (values) {
            const { id } = this.data
            return { id, ...values }
        },
    },
}
</script>
