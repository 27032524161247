<template>
    <div>
        <h1>ตารางราคา</h1>
        <!-- <a-button
            type="primary"
            :loading="basePriceProcessing"
            @click="reload">
            Refresh
        </a-button> -->

        <!-- <p>
            <a-form-item
                label="Base Price"
                :validate-status="basePriceDirty ? 'warning' : ''"
                :help="basePriceDirty ? 'Doesn\'t save yet.' : ''">
                <a-skeleton v-if="loading" :paragraph="false" />
                <div v-else>
                    <a-input-number
                        v-model="basePrice"
                        :min="0"
                        @change="()=> basePriceDirty = true" /> Bath/Timeslot(15 min)
                    <a-button
                        type="primary"
                        :loading="basePriceProcessing"
                        @click="saveChangeBasePrice">
                        Save
                    </a-button>
                </div>
            </a-form-item>
        </p> -->

        <!-- <h2>Custom Pricing</h2> -->
        <a-button
            v-if="!enableEditMode"
            type="primary"
            class="field-price__top-table-button"
            @click="()=>enableEditMode = true">
            แก้ไข
        </a-button>
        <a-button
            v-if="enableEditMode"
            class="field-price__top-table-button"
            type="primary"
            :loading="priceRulesProcessing"
            @click="saveChangePriceRules">
            บันทึก
        </a-button>
        <a-button
            v-if="enableEditMode"
            class="field-price__top-table-button"
            @click="rollbackToOriginal">
            ยกเลิก
        </a-button>
        <template v-if="enableEditMode">
            <FieldPriceRuleCreateForm :data="{ priceRules: entities }" @create="handleCreateNewRules" />
        </template>
        <VTable
            :style="{ marginBottom: '16px' }"
            :columns="smartColumns"
            :data-source="dataSourcePretty"
            :pagination="false"
            :loading="loading">
            <template
                slot="weekDay"
                slot-scope="text">
                <template v-if="!enableEditMode">
                    <template v-if="text">
                        <a-tag
                            :color="getColorFromWeekDay(text)">
                            {{ text }}
                        </a-tag>
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
                <template v-else>
                    <a-checkable-tag
                        v-for="day in weekDays"
                        :key="day"
                        :checked="text && text.findIndex(item=>item === day) !== -1"
                        @change="(checked) => handleWeekDayChange(day, checked)">
                        {{ day }}
                    </a-checkable-tag>
                </template>
            </template>
            <template
                v-slot:rangeTime="{ text }">
                <template v-if="text && text.length === 2">
                    {{ text[0].format("HH:mm") }} - {{ text[1].format("HH:mm") | endTime }} น.
                </template>
                <template v-else>
                    -
                </template>
            </template>
            <!-- <template slot="price" slot-scope="text,record">
                <template v-if="!enableEditMode">
                    {{ text }}
                </template>
                <template v-else>
                    <a-input-number
                        size="small"
                        :min="0"
                        :value="text"
                        @change="(value)=>onPriceChange(record.id,value)" />
                </template>
            </template> -->
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSourcePretty.length"
                    title="แน่ใจว่าจะลบ?"
                    @confirm="() => onDelete(record)">
                    <a href="javascript:;"><a-icon type="delete" />ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import EntitiesPage from '@/mixins/EntitiesPage'
import { rootActions } from '@/store/types'
import FieldPriceRuleCreateForm from '@/components/FieldPriceRuleCreateForm.vue'
import { getAllWeekDays } from '@/utils/utils'
import VTable from '@/components/VTable.vue'
import MixinFieldPriceRule from '@/mixins/MixinFieldPriceRule'

export default {
    components: {
        FieldPriceRuleCreateForm,
        VTable,
    },
    mixins: [EntitiesPage, MixinFieldPriceRule],
    props: {
        fieldId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            moment,
            weekDays: getAllWeekDays(),
            enableEditMode: false,
            basePriceLoading: true,
            priceRulesProcessing: false,
            basePriceProcessing: false,
            basePriceDirty: false,
            basePrice: undefined,
            columns: [
                {
                    title: 'วัน',
                    dataIndex: 'weekDay',
                    // scopedSlots: { customRender: 'weekDay' },
                    customRender: (text, row, index) => {
                        function isDuplicateWithAboveRow (list, id) {
                            if (id === 0) return false
                            return list[id - 1].weekDay === list[id].weekDay
                        }
                        function isHeadRow (list, id) {
                            if (id === 0 || !isDuplicateWithAboveRow(list, id)) return true
                            return false
                        }
                        function getCountChildRow (list, id) {
                            return list.filter((item) => item.weekDay === list[id].weekDay).length
                        }
                        if (isHeadRow(this.dataSourcePretty, index)) {
                            const tagColor = this.getColorFromWeekDay(text)
                            const weekDayTh = this.$options.filters.formatWeekdayEnum(text)
                            return {
                                children: <a-tag
                                    color={tagColor}>
                                    {weekDayTh}
                                </a-tag>,
                                attrs: {
                                    rowSpan: getCountChildRow(this.dataSourcePretty, index),
                                },
                            }
                        }
                        return {
                            attrs: {
                                rowSpan: 0,
                            },
                        }
                    },
                },
                {
                    title: 'ช่วงเวลา (เริ่ม-จบ)',
                    dataIndex: 'rangeTime',
                    scopedSlots: { customRender: 'rangeTime' },
                },
                {
                    title: 'ราคา/ชม.',
                    dataIndex: 'price',
                    width: '30%',
                    scopedSlots: { customRender: 'currency' },
                },
            ],
        }
    },
    computed: {
        dataSourcePretty () {
            return [...this.dataSource].sort(this.sortRule)
        },
        smartColumns () {
            if (this.enableEditMode) {
                return [...this.columns, {
                    title: 'การจัดการ',
                    dataIndex: 'operation',
                    width: '10%',
                    scopedSlots: { customRender: 'operation' },
                }]
            }
            return [...this.columns]
        },
        newToBeDisableRules () {
            return this.entities.filter((e) => this.dataSource.findIndex((data) => data.id === e.id) === -1)
        },
        newToBeDisableRuleIds () {
            return this.newToBeDisableRules.map((e) => e.id)
        },
        newToBeInsertRules () {
            return this.dataSource.filter((e) => e.fromServer !== true)
        },
    },
    // async created () {
    //     const res = await this.$store.dispatch(rootActions.LOAD_BASE_PRICE, this.fieldId)
    //     if (res.isSuccess()) {
    //         this.basePrice = res.data
    //     }
    //     this.basePriceLoading = false
    // },
    methods: {
        sortRule (a, b) {
            if (this.getEnumFromWeekday(a.weekDay) > this.getEnumFromWeekday(b.weekDay)) {
                return 1
            }
            if (this.getEnumFromWeekday(a.weekDay) < this.getEnumFromWeekday(b.weekDay)) {
                return -1
            }
            if (a.rangeTime && !b.rangeTime) {
                return 1
            }
            if (!a.rangeTime && b.rangeTime) {
                return -1
            }
            if (a.rangeTime && b.rangeTime) {
                const aTime = this.convertToCurrentDate(`${a.rangeTime[0].hour()}:${a.rangeTime[0].minute()}`)
                const bTime = this.convertToCurrentDate(`${b.rangeTime[0].hour()}:${b.rangeTime[0].minute()}`)
                if (aTime.isBefore(bTime)) {
                    return -1
                }
                if (aTime.isAfter(bTime)) {
                    return 1
                }
                return 0
            }

            return 0
        },
        convertToCurrentDate (time) {
            const day = moment()
            const splitTime = time.split(/:/)
            day.hours(parseInt(splitTime[0], 10)).minutes(parseInt(splitTime[1], 10)).seconds(0).milliseconds(0)
            return day
        },
        reload () {
            this.$forceUpdate()
        },
        rollbackToOriginal () {
            this.enableEdionDeletetMode = false
            this.updateDataSource(this.entities)
        },
        onPriceChange (id, value) {
            const target = this.dataSource.find((item) => item.id === id)
            Vue.set(target, 'price', value)
        },
        onDelete (rule) {
            const { id } = rule
            // this.onDeleteRule(rule)
            this.dataSource = [...this.dataSource.filter((item) => item.id !== id)]
        },
        getEnumFromWeekday (weekDay) {
            return this.weekDays.findIndex((item) => item === weekDay)
        },
        isCheckWeekDay () {

        },
        fetchEntities () {
            return this.$store.dispatch(rootActions.LOAD_FIELD_PRICE_RULES, this.fieldId)
        },
        getEntities () {
            return this.$store.state.field.priceRules
        },
        getColorFromWeekDay (weekDay) {
            const colors = {
                mon: 'cyan',
                tue: 'pink',
                wed: 'green',
                thu: 'orange',
                fri: 'blue',
                sat: 'purple',
                sun: 'red',
            }
            return colors[weekDay]
        },
        async saveChangePriceRules () {
            this.priceRulesProcessing = true
            if ((this.newToBeInsertRules && this.newToBeInsertRules.length > 0)
            || (this.newToBeDisableRuleIds && this.newToBeDisableRuleIds.length > 0)) {
                const res = await this.$store.dispatch(
                    rootActions.UPDATE_PRICE_RULES,
                    {
                        insertingRules: this.newToBeInsertRules
                            ? this.newToBeInsertRules.map((data) => ({ ...data, fieldId: this.fieldId })) : [],
                        disableRuleIds: this.newToBeDisableRuleIds
                            ? this.newToBeDisableRuleIds : [],
                    }
                    ,
                )
                if (res.isSuccess()) {
                    this.enableEditMode = false
                }
            } else {
                this.enableEditMode = false
            }
            this.priceRulesProcessing = false
        },
        async saveChangeBasePrice () {
            this.basePriceProcessing = true
            const res = await this.$store.dispatch(rootActions.UPDATE_BASE_PRICE, this.basePrice)
            if (res.isSuccess()) {
                this.basePriceDirty = false
            }
            this.basePriceProcessing = false
        },
        handleCreateNewRules (rules) {
            const newRules = this.applyNewRules(this.dataSource, rules)
            const { updateRules } = this
            updateRules(newRules)
        },
        updateRules (rules) {
            this.dataSource = rules
        },
    },
}
</script>

<style lang="stylus">
.field-price__top-table-button
    margin-bottom: 16px
    float: right
</style>
