export default class ModelExtractedPriceRule {
    constructor ({
        start,
        endBefore,
        price,
    }) {
        Object.assign(this, {
            start,
            endBefore,
            price,
        })
    }

    isActiveAt (dateTime) {
        const { start, endBefore } = this
        if (dateTime.isSame(start)) return true
        return dateTime.isAfter(start) && dateTime.isBefore(endBefore)
    }
}
