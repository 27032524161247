import { rootActions } from '@/store/types'
import { ProductType } from '@/utils/enum'

export default {
    methods: {
        async cancelProduct () {
            const res = await this.$store.dispatch(
                rootActions.CANCEL_PRODUCT,
                { id: this.product.id, type: this.product.type },
            )
            if (res.isSuccess()) {
                this.$emit('success', res.data)
            }
            return res
        },
        async deleteProduct () {
            let res
            if (this.product.type === ProductType.RESERVE) {
                res = await this.$store.dispatch(
                    rootActions.CANCEL_PRODUCT_OFFER,
                    this.product.id,
                )
            } else {
                res = await this.$store.dispatch(
                    rootActions.DELETE_PRODUCT,
                    { type: this.product.type, id: this.product.id },
                )
            }

            if (res.isSuccess()) {
                this.$emit('delete', this.product.id)
            } else if (res.getErrorGqlCode() === 'UNDELETABLE') {
                const { cancelProduct } = this
                this.$confirm({
                    title: 'เนื่องจากมีการซื้อเกิดขึ้นแล้ว ระบบจะทำการลบไม่ได้ แต่ทำการยกเลิกให้ได้',
                    content: (
                        <div>
                            <p>การยกเลิก คือระบบจะทำการคืนเงินให้ลูกค้า
                                และยังคงมีการค้นหากิจกรรมนี้เจอแต่ขึ้นว่ายกเลิกไปแล้ว</p>
                        </div>
                    ),
                    centered: true,
                    onOk () {
                        return cancelProduct()
                    },
                    onCancel () {
                        // do nothing
                    },
                })
            }
        },
    },
}
