var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "boost-buffet-or-party-event__tag",
      class: {
        "boost-buffet-or-party-event__tag--buffet": _vm.isBuffet,
        "boost-buffet-or-party-event__tag--tournament": _vm.isTournament
      }
    },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("getEventTypePrettyString")(_vm.product)) +
          "\n    "
      ),
      _c(
        "span",
        { staticClass: "boost-buffet-or-party-event__info" },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.product.noCheckedOutItem ? _vm.product.noCheckedOutItem : 0
              ) +
              "\n        /" +
              _vm._s(_vm.product.totalImportToStock) +
              "\n        "
          ),
          _c("a-icon", { attrs: { type: "team" } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }