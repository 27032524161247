<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <StadiumBoostCreateAndUpdateFormItems
            :form="form"
            :data="data"
            :is-offer-mode="isOfferMode"
            type="create" />
    </VForm>
</template>
<script>
import StadiumBoostCreateAndUpdateFormItems from '@/components/StadiumBoostCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import { rootActions } from '@/store/types'
import { ProductType } from '@/utils/enum'
import VApiResponse from '@/utils/VApiResponse'
import { isReserveType } from '@/utils/utils'

export default {
    components: {
        StadiumBoostCreateAndUpdateFormItems,
        VForm,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
        isOfferMode: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        isReserveType,
        async submit (values) {
            if (this.isOfferMode || this.isReserveType(values)) {
                return new VApiResponse.SUCCESS(values)
            }
            const { variants, localCustomers, ...others } = values
            const res = await this.$store.dispatch(
                rootActions.ADD_PRODUCT, {
                    ...others,
                    variants: variants ? variants
                        .filter((el) => el != null) : undefined,
                    type: ProductType.BOOST,
                    localCustomers: localCustomers ? localCustomers
                        .filter((el) => el != null) : [],
                },
            )
            return res
        },
    },
}
</script>
