<template>
    <div>
        <h3>ลูกค้าจาก Lenkila</h3>
        <a-tabs type="card">
            <a-tab-pane key="1" tab="ใช้งานได้">
                <TableProductCustomers :id="id" :is-active="true" />
            </a-tab-pane>
            <a-tab-pane key="2" tab="ยกเลิกไปแล้ว">
                <TableProductCustomers :id="id" :is-active="false" />
            </a-tab-pane>
        </a-tabs>

    </div>
</template>
<script>
import TableProductCustomers from '@/components/TableProductCustomers.vue'

export default {
    components: {
        TableProductCustomers,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        enableManagerMode: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="stylus">
.product-customers__action-button
    float: right
</style>
