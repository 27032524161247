var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field-price-rule-create-form" },
    [
      _c("a-alert", {
        style: { marginBottom: "16px" },
        attrs: {
          message: "ต้องกดบันทึกแล้วเท่านั้น การแก้ไขถึงจะสำเร็จ",
          banner: "",
          closable: ""
        }
      }),
      _c("h3", [_vm._v("เพิ่ม กฎราคา ใหม่")]),
      _c(
        "a-form",
        { attrs: { form: _vm.form }, on: { submit: _vm.handleSubmit } },
        [
          _c(
            "a-form-item",
            _vm._b(
              {
                attrs: {
                  label: "วัน",
                  "validate-status": _vm.getValidateDays(),
                  help: _vm.getHelpMsgDays()
                }
              },
              "a-form-item",
              _vm.formItemLayout,
              false
            ),
            _vm._l(_vm.weekDays, function(day) {
              return _c(
                "a-checkable-tag",
                {
                  key: day,
                  attrs: { checked: _vm.selectedDays.indexOf(day) > -1 },
                  on: {
                    change: function(checked) {
                      return _vm.handleWeekDayChange(day, checked)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("formatWeekdayEnum")(day)) +
                      "\n            "
                  )
                ]
              )
            }),
            1
          ),
          _vm._l(_vm.form.getFieldValue("keys"), function(k, index) {
            return _c(
              "a-form-item",
              _vm._b(
                {
                  key: k,
                  staticClass: "field-price-rule-create-form__range-time",
                  attrs: {
                    label: index === 0 ? "ช่วงเวลา" : "",
                    "has-feedback": "",
                    required: false
                  }
                },
                "a-form-item",
                index === 0
                  ? _vm.formItemLayout
                  : _vm.formItemLayoutWithOutLabel,
                false
              ),
              [
                _c(
                  "VFormItemRangeTime",
                  {
                    attrs: {
                      shink: true,
                      form: _vm.form,
                      "minute-step": 15,
                      "is-prevent-start-before-end": true,
                      "name-prop-start-time": "times[" + k + "][0]",
                      "name-prop-end-time": "times[" + k + "][1]"
                    }
                  },
                  [
                    _vm.form.getFieldValue("keys").length >= 1
                      ? _c("a-icon", {
                          staticClass: "dynamic-delete-button",
                          attrs: { type: "minus-circle-o" },
                          on: {
                            click: function() {
                              return _vm.remove(k)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "a-form-item",
            _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "60%" },
                  attrs: { type: "dashed" },
                  on: { click: _vm.add }
                },
                [
                  _c("a-icon", { attrs: { type: "plus" } }),
                  _vm._v(" เพิ่มช่วงเวลา\n            ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            _vm._b(
              { attrs: { label: "ราคา" } },
              "a-form-item",
              _vm.formItemLayout,
              false
            ),
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["price"],
                    expression: "[ 'price' ]"
                  }
                ],
                attrs: { min: 0 }
              }),
              _vm._v(" บาท/ชม.\n        ")
            ],
            1
          ),
          _c(
            "a-form-item",
            _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
            [
              _c("a-button", { attrs: { "html-type": "submit" } }, [
                _vm._v("\n                เพิ่ม\n            ")
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }