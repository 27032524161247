var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "เพิ่มลูกค้า" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("ProductLocalCustomerCreateForm", {
            attrs: { "max-people": _vm.maxPeople },
            on: { success: _vm.onCreateEntity }
          })
        ],
        1
      ),
      _c(
        "VModal",
        {
          attrs: { title: "แก้ไขข้อมูลลูกค้า" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("ProductLocalCustomerUpdateForm", {
            attrs: { data: _vm.selectedEntity, "max-people": _vm.maxPeople },
            on: { success: _vm.onUpdate }
          })
        ],
        1
      ),
      _c("h3", [_vm._v("ลูกค้าจากนอกระบบ")]),
      _c(
        "a-button",
        {
          staticClass: "product-local-customers__action-button",
          attrs: { type: "primary" },
          on: { click: _vm.onClickCreate }
        },
        [_vm._v("\n        เพิ่มลูกค้า\n    ")]
      ),
      _c("VTable", {
        attrs: {
          "data-source": _vm.entities,
          "flexible-width": "240px",
          columns: _vm.columns,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow
        },
        scopedSlots: _vm._u([
          {
            key: "isPaid",
            fn: function(ref) {
              var record = ref.record
              return [
                _c("a-checkbox", {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  },
                  model: {
                    value: record.isPaid,
                    callback: function($$v) {
                      _vm.$set(record, "isPaid", $$v)
                    },
                    expression: "record.isPaid"
                  }
                })
              ]
            }
          },
          {
            key: "numberOfPeople",
            fn: function(ref) {
              var text = ref.text
              return [
                _vm._v("\n            " + _vm._s(text) + " "),
                _c("a-icon", { attrs: { type: "team" } })
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                _vm.dataSource.length
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function() {
                            return _vm.onDelete(record.id)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ลบ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }