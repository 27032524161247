var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-type-details" },
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Color"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.memberType.color) + "\n        "),
          _c("div", {
            staticClass: "member-type-details__color-preview",
            style: { backgroundColor: _vm.memberType.color }
          })
        ]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Name"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.memberType.name) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Description"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.memberType.description) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Type"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.memberType.type) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.memberType.type === "yearly",
              expression: "memberType.type === 'yearly'"
            }
          ],
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Year Amount"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.expiredInYear) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.memberType.type === "monthly",
              expression: "memberType.type === 'monthly'"
            }
          ],
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Month Amount"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.expiredInMonth) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.memberType.type !== "permanant",
              expression: "memberType.type !== 'permanant'"
            }
          ],
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Day Amount"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.memberType.expiredInDay) + "\n    ")]
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "Price"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.memberType.price) + "\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }