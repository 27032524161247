var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    {
      attrs: {
        title: _vm.title,
        borderless: _vm.borderless,
        "title-style": _vm.titleStyle
      }
    },
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "สร้าง ประเภทสมาชิก",
            width: "50%",
            centered: "centered",
            footer: null,
            "destroy-on-close": ""
          },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("MemberTypeCreateForm", {
            on: { success: _vm.onCreateEntityPagination }
          })
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticClass: "member-types__add-button",
          attrs: { type: "primary" },
          on: { click: _vm.openCreateForm }
        },
        [_vm._v("\n        เพิ่ม\n    ")]
      ),
      _vm.newEntities.length > 0
        ? _c(
            "SectionNew",
            [
              _c("MemberTypes", {
                attrs: {
                  "data-source": _vm.newEntities,
                  loading: _vm.loading,
                  "custom-row": _vm.createClickToEditRow
                },
                on: { delete: _vm.onDeleteEntityPagination },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.$scopedSlots, function(index, name) {
                      return {
                        key: name,
                        fn: function(text, record) {
                          return [
                            _vm._t(name, null, null, {
                              text: text,
                              record: record
                            })
                          ]
                        }
                      }
                    })
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("MemberTypes", {
        attrs: {
          "data-source": _vm.entities,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow
        },
        on: { delete: _vm.onDeleteEntityPagination },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function(index, name) {
              return {
                key: name,
                fn: function(text, record) {
                  return [
                    _vm._t(name, null, null, { text: text, record: record })
                  ]
                }
              }
            })
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }