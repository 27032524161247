<template>
    <div class="productLocalCustomerFormItem">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างลูกค้า">
            <CustomerCreateForm @success="onSuccessCreateCustomer" />
        </VModal>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ลูกค้า">
            <a-select
                v-decorator="[ 'localStadiumCustomerId' ,
                               { rules:
                                   [
                                       { required: true,
                                         message: 'เลือกลูกค้า', } ] } ]"
                show-search
                placeholder="เลือกลูกค้า"
                option-filter-prop="children"
                style="width: 240px;"
                :filter-option="filterOption"
                :loading="isLoadLocalStadiumCustomers"
                @change="onLocalStadiumCustomerChange"
                @focus="loadLocalStadiumCustomers">
                <div slot="dropdownRender" slot-scope="menu">
                    <VNodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0;" />
                    <div
                        style="padding: 4px 8px; cursor: pointer;"
                        @mousedown="e => e.preventDefault()"
                        @click="openCreateForm">
                        <a-icon type="plus" /> เพิ่มลูกค้า
                    </div>
                </div>
                <a-select-option
                    v-for="localStadiumCustomer in localCustomers"
                    :key="localStadiumCustomer.id"
                    :value="localStadiumCustomer.id">
                    {{ localStadiumCustomer.firstName }} {{ localStadiumCustomer.lastName }}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item
            v-show="canBuyMultiple"
            label="จำนวนคน"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ 'numberOfPeople',

                      {
                          initialValue: 1,
                          rules:
                              [ { required: true,
                                  message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                :min="1" /> คน
        </a-form-item>
        <PeopleCreateAndUpdateFormItems
            :form="form"
            action-type="create"
            :data="customerData"
            :is-require-last-name="false"
            :read-only="true"
            :enable-note-input="true"
            :enable-address-input="false" />
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="จ่ายเงินแล้ว">
            <a-checkbox
                v-decorator="[ 'isPaid',{
                    initialValue: false,valuePropName: 'checked' } ]" />
        </a-form-item>
    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import { rootActions, Getters } from '@/store/types'
import MixinCreateFormModal from '@/mixins/MixinCreateFormModal'
import VModal from '@/components/VModal.vue'
import CustomerCreateForm from '@/components/CustomerCreateForm.vue'

export default {
    components: {
        CustomerCreateForm,
        VModal,
        PeopleCreateAndUpdateFormItems,
        VNodes: {
            functional: true,
            render: (_h, ctx) => ctx.props.vnodes,
        },
    },
    mixins: [FormItems, MixinCreateFormModal],
    props: {
        canBuyMultiple: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            isLoadLocalStadiumCustomers: false,
            updatedCustomerData: null,
        }
    },
    computed: {
        localCustomers () {
            return this.$store.state.customers.customers
        },
        customerData () {
            return this.updatedCustomerData ? this.updatedCustomerData : this.data
        },
    },
    beforeMount () {
        this.loadLocalStadiumCustomers()
    },
    methods: {
        mapDataToFormItems (data) {
            const { isPaid } = data
            return {
                isPaid,
                numberOfPeople: data.numberOfPeople,
                localStadiumCustomerId: data.localStadiumCustomerId,
            }
        },
        async loadLocalStadiumCustomers () {
            this.isLoadLocalStadiumCustomers = true
            await this.$store.dispatch(rootActions.LOAD_CUSTOMERS, { page: 1, size: 200 })
            this.isLoadLocalStadiumCustomers = true
        },
        filterOption (input, option) {
            return (
                option.componentOptions.children[0]
                    .text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        onLocalStadiumCustomerChange (customerId) {
            this.updatedCustomerData = this.$store.getters[Getters.customers.GET_CUSTOMER](customerId)
        },
        onSuccessCreateCustomer (customer) {
            this.closeCreateForm()
            this.setCustomerId(customer.id)
        },
        setCustomerId (id) {
            this.form.setFieldsValue({
                localStadiumCustomerId: id,
            })
            // set field value doesn't trigger event change
            // so we need to manual call this method
            this.onLocalStadiumCustomerChange(id)
        },
    },
}
</script>

<style lang="stylus">
.productLocalCustomerFormItem
    .ant-input[disabled]
        color: black
</style>
