<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <StadiumFieldCreateAndUpdateFormItems :form="form" />
    </VForm>
</template>
<script>
import StadiumFieldCreateAndUpdateFormItems from '@/components/StadiumFieldCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import { rootActions } from '@/store/types'

export default {
    components: {
        StadiumFieldCreateAndUpdateFormItems,
        VForm,
    },
    props: {
        isOfficial: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            createdEntity: null,
        }
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_FIELD, { ...values },
            )
            return res
        },
    },
}
</script>
