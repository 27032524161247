<template>
    <div>
        <a-button
            type="primary"
            class="product-customers__action-button"
            @click="copyCustomersToClipboard">
            <a-icon type="copy" />
            คัดลอกรายชื่อ
        </a-button>
        <TableTxItems
            :shink="true"
            :data-source="dataSource"
            :loading="loading"
            :pagination="pagination"
            :is-active="isActive"
            @cancel="onCancel"
            @change="handleTableChange" />
    </div>
</template>

<script>
import { rootActions, Getters } from '@/store/types'
import EntitiesPagination from '@/mixins/EntitiesPagination'
import TableTxItems from '@/components/TableTxItems.vue'

export default {
    components: {
        TableTxItems,
    },
    mixins: [EntitiesPagination],
    props: {
        id: {
            type: String,
            required: true,
        },
        enableManagerMode: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            size: 100,
        }
    },
    computed: {
        boost () {
            return this.$store.getters[Getters.products.GET_PRODUCT](this.id)
        },
    },
    methods: {
        async fetchEntityPagination () {
            const {
                id, page, size, isActive,
            } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_PRODUCT_TX_ITEM,
                {
                    productId: id, page, size, isActive,
                },
            )
            return res
        },
        getAllEntities () {
            return this.boost.txItems
        },
        getTextColumns () {
            return this.columns.filter((item) => item.scopedSlots.customRender !== 'operation' && item.scopedSlots.customRender !== 'id')
        },
        copyCustomersToClipboard () {
            const listCustomerString = this.getEntities()
                .map((e) => e.customer)
                .reduce(
                    (result, customer) => `${result} - ${ this.customerToString(customer)}\n`,
                    '',
                )
            navigator.clipboard.writeText(listCustomerString)
        },
        customerToString (customer) {
            return `${customer.firstName } ${ customer.lastName}`
        },
        async onCancel (id) {
            await this.$store.dispatch(rootActions.CANCEL_PRODUCT_TX_ITEM, { productId: this.id, txItemId: id })
        },
    },
}
</script>

<style lang="stylus">
.product-customers__action-button
    float: right
</style>
