var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "customer-create-form",
      attrs: { form: _vm.form, layout: "horizontal" },
      on: { submit: _vm.handleSubmit }
    },
    [
      _c("StadiumBoostLocalCustomersCreateAndUpdateFormItems", {
        attrs: {
          "can-buy-multiple": _vm.maxPeople > 1,
          form: _vm.form,
          "action-type": "create",
          data: _vm.data
        }
      }),
      _c(
        "a-form-item",
        { attrs: { "wrapper-col": { span: 12, offset: 4 } } },
        [
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.isProcessing
              }
            },
            [_vm._v("\n            บันทึก\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }