<template>
    <div>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขการโปรโมท">
            <StadiumBoostUpdateForm
                :disable-update="false"
                :boost-id="selectedEntityId"
                @success="closeUpdateForm" />
        </VModal>
        <VTable
            :enable-scroll="true"
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            :pagination="shink ? { ...pagination,position: 'bottom' } : pagination"
            :on-refresh="onRefresh"
            @change="handleTableChange">
            <template
                v-slot:numberOfPeople="{ record }">
                {{ record.mirrorVariant.amountPerUnit * record.amount }} <a-icon type="team" /><br>
                ( {{ record.amount }}x{{ record.mirrorVariant.amountPerUnit }} คน)
            </template>
            <template
                v-slot:amountPerUnit="{ text }">
                {{ text }} คน
            </template>
            <template
                v-slot:paymentMethod="{ text,record }">
                <a-tag
                    :color="text === PaymentMethod.PAY_AT_STADIUM ? 'blue' : 'orange'">
                    {{ getMsgFromPaymentMethod(text).toUpperCase() }}
                </a-tag>
                <a-tag
                    :color=" getColorOfStage (record.stage)">
                    {{ getMsgFromTxItemStage(record.stage).toUpperCase() }}
                </a-tag>
            </template>
            <template
                v-slot:operation="{ record }">
                <!-- <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="onConfirmDelete(record)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm> -->
                <a-popconfirm
                    v-if="dataSource.length &&
                        canCancelStage(record.stage) "
                    title="ยืนยันการยกเลิก?"
                    @confirm="()=>$emit('cancel', record.id)">
                    <a href="javascript:;" @click.stop>ยกเลิก</a>
                </a-popconfirm>
                <div v-if="dataSource.length && !canCancelStage(record.stage) ">
                    <a-tag
                        :color="getColorOfStage (record.stage)">
                        {{ getMsgFromTxItemStage(record.stage).toUpperCase() }}
                    </a-tag>
                </div>
            </template>
            <template
                v-slot:product="{ text }">
                <a-card
                    style="cursor: pointer;"
                    @click.stop="openUpdateForm(text.id)">
                    <ProductTag :product="text" />
                    {{ text.field.name }} <br> {{ text | getStartEndPrettyString }}
                </a-card>
            </template>
        </vtable>
    </div>
</template>
<script>
import VTable from '@/components/VTable.vue'
import MixinTable from '@/mixins/MixinTable'
import ProductTag from '@/components/ProductTag.vue'
import {
    getMsgFromTxItemStage, getMsgFromPaymentMethod, PaymentMethod, TxItemStage,
} from '@/utils/enum'
import VModal from '@/components/VModal.vue'
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'

export default {
    components: {
        VTable,
        ProductTag,
        StadiumBoostUpdateForm: () => import('./StadiumBoostUpdateForm.vue'),
        VModal,
    },
    mixins: [MixinTable, MixinUpdateFormModal],
    props: {
        dataSource: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: Object,
            default: undefined,
        },
        shink: {
            type: Boolean,
            default: false,
        },
        onRefresh: {
            type: Function,
            default: null,
        },
        isActive: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            validStages: [
                TxItemStage.VALID,
                TxItemStage.REDEEMED,
                TxItemStage.EXPIRED,
                TxItemStage.USED],
            columns: [
                ...!this.shink
                    ? [{
                        title: 'เวลา',
                        dataIndex: 'updatedOn',
                        scopedSlots: { customRender: 'dateTime' },
                        width: 100,
                    },
                    {
                        title: 'สินค้า',
                        dataIndex: 'mirrorProduct',
                        scopedSlots: { customRender: 'product' },
                        width: 240,
                    }] : [],
                // {
                //     title: 'สถานะ',
                //     dataIndex: 'stage',
                //     width: '50',
                //     scopedSlots: { customRender: 'stage' },
                // },
                {
                    title: 'จำนวนคน',
                    key: 'people',
                    scopedSlots: { customRender: 'numberOfPeople' },
                    width: 100,
                },
                // {
                //     title: 'จำนวนสินค้า',
                //     dataIndex: 'amount',
                //     width: 72,
                // },
                {
                    title: 'ประเภท',
                    dataIndex: 'mirrorVariant.name',
                    width: 72,
                },
                // {
                //     title: '1 ชิ้นใช้ได้',
                //     dataIndex: 'mirrorVariant.amountPerUnit',
                //     scopedSlots: { customRender: 'amountPerUnit' },
                //     width: 96,
                // },
                // {
                //     title: 'ชื่อผู้ใช้',
                //     dataIndex: 'customer.username',
                //     scopedSlots: { customRender: 'text' },
                //     width: 100,
                // },
                {
                    title: 'ชื่อจริง',
                    dataIndex: 'customer.firstName',
                    scopedSlots: { customRender: 'text' },
                    // width: 120,
                },
                {
                    title: 'นามสกุล',
                    dataIndex: 'customer.lastName',
                    scopedSlots: { customRender: 'text' },
                    // width: 120,
                },
                // {
                //     title: 'ชื่อเล่น',
                //     dataIndex: 'customer.nickName',
                //     width: 100,
                //     scopedSlots: { customRender: 'text' },
                // },
                {
                    title: 'เบอร์โทร',
                    dataIndex: 'customer.phoneNumber',
                    scopedSlots: { customRender: 'text' },
                    width: 140,
                },
                {
                    title: 'อีเมล(Email)',
                    dataIndex: 'customer.email',
                    scopedSlots: { customRender: 'text' },
                    width: 140,
                },
                {
                    title: 'ราคา(บาท)',
                    dataIndex: 'price',
                    width: 100,
                    // fixed: 'right',
                    scopedSlots: { customRender: 'currency' },
                },
                {
                    title: 'ช่องทางชำระเงิน',
                    dataIndex: 'paymentMethod',
                    width: 100,
                    fixed: 'right',
                    scopedSlots: { customRender: 'paymentMethod' },
                },
                this.isActive
                    ? {
                        title: 'การจัดการ',
                        dataIndex: 'operation',
                        width: 100,
                        fixed: 'right',
                        scopedSlots: { customRender: 'operation' },
                    } : {},
            ],
            TxItemStage,
            PaymentMethod,
        }
    },
    methods: {
        getMsgFromTxItemStage,
        getMsgFromPaymentMethod,
        getTextColumns () {
            return this.columns.filter((item) => item.scopedSlots.customRender !== 'operation' && item.scopedSlots.customRender !== 'id')
        },
        isCompleteStage (stage) {
            return this.validStages.includes(stage)
                        || stage === TxItemStage.REFUNDED
        },
        canCancelStage (stage) {
            return this.validStages.includes(stage) || stage === TxItemStage.CHECKED_OUT_CART
        },
        getColorOfStage (stage) {
            console.log(stage)
            if (this.isCompleteStage(stage)) return 'green'
            if (stage === TxItemStage.CANCELED) return 'grey'
            return 'pink'
        },
    },
}
</script>
