<template>
    <a-spin :spinning="loading" :delay="500">
        <VueCal
            id="vuecal"
            ref="myButton"
            :active-view="activeView"
            :time-cell-height="timeCellHeight"
            :selected-date="selectedDate"
            :disable-views="[ 'years', 'year' ]"
            :events="events"
            :time="true"
            :show-time-in-cells="true"
            :snap-to-time="15"
            :on-event-click="onClickEvent"
            :editable-events="{ title: false, drag: false, resize: false, delete: false, create: true }"
            :on-event-create="onSmartEventCreate"
            :split-days="splitDays"
            :sticky-split-labels="true"
            @update:activeView="(value)=>$emit('update:activeView', value)"
            @event-drag-create="onSmartDragToCreate"
            @ready="onSmartViewChange"
            @view-change="onSmartViewChange">
            <template #title="{ title, view }">
                <span :class="{ 'vuecal__title--today_tag': (view.startDate ? view.startDate.isToday() : false) }">
                    {{ title }}<span v-if="view.startDate.isToday() " class="vuecal__title--today_tag">วันนี้</span>
                </span>
            </template>
            <template #weekday-heading="{ heading,view }">
                <div
                    grow
                    class="vuecal__flex weekday-label"
                    :class="{ 'vuecal__title--today_tag': (heading.date ? heading.date.isToday() : false) }">
                    <span class="full">{{ heading.label }}</span>
                    <span class="small">{{ heading.label.substring(0,3) }}</span>
                    <span class="xsmall">{{ heading.label.substring(0,1) }}</span>
                    <span v-if="view.id == 'week'">&nbsp;{{ heading.date.getDate() }}</span>
                    <span
                        v-if="heading.date ? heading.date.isToday() : false "
                        class="vuecal__title--today_tag">วันนี้</span>
                </div>
            </template>
            <template #cell-content="{ cell, view, events: _events, goNarrower }">
                <span v-if="![ 'week', 'day' ].includes(view.id)" class="vuecal__cell-date">
                    {{ cell.content }} {{ cell.today ? "วันนี้" : "" }}
                </span>
                <span v-if="[ 'week', 'day' ].includes(view.id) && !_events.length" class="vuecal__no-event">
                    0 กิจกรรม
                </span>
                <div
                    v-if="![ 'week', 'day' ].includes(view.id)"
                    class="vuecal__cell-events">
                    <div
                        v-for="(event,index) in _events.slice(0, 3)"
                        :key="event.id"
                        class="vuecal__event"
                        :class="index < 2 ? event.class : 'view-more'">
                        <div v-if="index < 2" @click="onClickEvent(event)">
                            <div class="vuecal__event-title">
                                <div
                                    v-if="event.noSoldItem >= event.totalImportToStock"
                                    class="product-tag product-tag__addional-info product-tag--full">
                                    <span>
                                        <a-icon type="check" />
                                        จ่ายครบแล้ว
                                    </span>
                                </div>
                                {{ event.title }}
                            </div>
                            <div class="vuecal__event-content">
                                {{ event.noCheckedOutItem ? event.noCheckedOutItem : 0 }}
                                /{{ event.totalImportToStock }}
                                <a-icon type="team" />
                            </div>
                            <small class="vuecal__event-time">
                                <span>{{ event.start.formatTime("HH:mm") }}</span>-
                                <span>{{ event.end.formatTime("HH:mm") }}</span>
                            </small>
                        </div>
                        <div v-else-if="index == 2" @click="goNarrower">
                            <div class="vuecal__event-title">
                                + {{ _events.length - 2 }} กิจกรรม <small><span>ดูเพิ่มเติม</span></small>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #event="{ event, view }">
                <div
                    v-if="![ 'week', 'day' ].includes(view.id)">
                    <div class="vuecal__event-title">
                        <div
                            v-if="event.noSoldItem >= event.totalImportToStock"
                            class="product-tag product-tag__addional-info product-tag--full">
                            <span>
                                <a-icon type="check" />
                                จ่ายครบแล้ว
                            </span>
                        </div>
                        {{ event.title }}
                    </div>
                    <div class="vuecal__event-content">
                        {{ event.noCheckedOutItem ? event.noCheckedOutItem : 0 }}
                        /{{ event.totalImportToStock }}
                        <a-icon type="team" />
                    </div>
                    <small class="vuecal__event-time">
                        <span>{{ event.start.formatTime("HH:mm") }}</span>-
                        <span>{{ event.end.formatTime("HH:mm") }}</span>
                    </small>
                </div>
            </template>
            <template #split-label="{ split }">
                <strong :style="`color: ${split.color}`">{{ split.label }}</strong>
            </template>
        </VueCal>
    </a-spin>
</template>
<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { isEmpty } from '@/utils/utils'
import moment from 'moment'

export default {
    components: { VueCal },
    model: {
        prop: 'activeView',
        event: 'update:activeView',
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        activeView: {
            type: String,
            default: 'month',
        },
        selectedDate: {
            type: Date,
            default: null,
        },
        events: {
            type: Array,
            default: () => [],
        },
        // ex
        // { id: 1, class: 'mom', label: 'Mom' },
        // { id: 2, class: 'dad', label: 'Dad', hide: false },
        // The id property is added automatically if none (starting from 1), but you can set a custom one.
        // If you need to toggle the splits, you must set the id explicitly.
        splitDays: {
            type: Array,
            default: () => [],
        },
        onViewChange: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
        onClickEvent: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
        onDragToCreate: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
        onCreateEvent: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
    },
    data () {
        return {
            timeCellHeight: 48,
            // Use for trigger something that require dom to completly change
            // ex. scrollTo
            // Can't use onViewChange method because it call on data change but Dom is not change yet
            domViewModeChangeObserver: null,
            deleteEventFunction: null,
        }
    },
    computed: {
        timelineStartHourFrom () {
            if (!this.timelineStartFrom) return null
            return this.timelineStartFrom.hour()
        },
        timelineStartFrom () {
            if (isEmpty(this.events) || this.events.length === 0) return null
            return this.events
                .map((e) => moment(e.start))
                .reduce((prev, current) => (current.isBefore(prev) ? current : prev))
        },
    },
    watch: {
        timelineStartFrom () {
            this.scrollToFirstEvent()
        },
    },
    mounted () {
        this.root = document.querySelector('#vuecal .vuecal__bg')
        this.registerDomViewModeChangeObserver()
    },
    beforeDestroy () {
        this.domViewModeChangeObserver.disconnect()
    },
    methods: {
        sortEvent (a, b) {
            const { start: aStartTxt, end: aEndTxt } = a
            const { start: bStartTxt, end: bEndTxt } = b
            const aStart = moment(aStartTxt, 'YYYY-MM-DD HH:mm')
            const bStart = moment(bStartTxt, 'YYYY-MM-DD HH:mm')
            const aEnd = moment(aEndTxt, 'YYYY-MM-DD HH:mm')
            const bEnd = moment(bEndTxt, 'YYYY-MM-DD HH:mm')
            if (aStart.isAfter(bStart)) {
                return 1
            }
            if (aStart.isBefore(bStart)) {
                return -1
            }
            if (aEnd.isAfter(bEnd)) {
                return 1
            }
            if (aEnd.isBefore(bEnd)) {
                return -1
            }
            return 0
        },
        registerDomViewModeChangeObserver () {
            // eslint-disable-next-line no-unused-vars
            this.domViewModeChangeObserver = new MutationObserver(((_mutations) => {
                this.scrollToFirstEvent()
            }))
            this.domViewModeChangeObserver.observe(document.querySelector('.vuecal__flex.vuecal__body'), {
                childList: true,
            })
        },
        isEmpty,
        onSmartViewChange (attrs) {
            this.onViewChange(attrs)
        },
        scrollToFirstEvent () {
            const calendar = document.querySelector('#vuecal .vuecal__bg')
            if (this.isMonthViewMode(calendar)) {
                return
            }
            if (this.timelineStartFrom == null) return
            const hours = this.timelineStartFrom.hours() + this.timelineStartFrom.minutes() / 60
            calendar.scrollTo({ top: hours * this.timeCellHeight, behavior: 'smooth' })
        },
        isMonthViewMode (calendar) {
            return calendar.classList.contains('vuecal__flex')
        },
        // Called when drag-create threshold is reached (when the event appears on screen),
        // but before releasing the drag; so, it should not open the dialog box yet.
        // return eventObj || false
        onSmartEventCreate (event, deleteEventFunction) {
            this.deleteEventFunction = deleteEventFunction
            if (isEmpty(this.onCreateEvent)) return event
            return this.onCreateEvent(event, deleteEventFunction)
        },
        onSmartDragToCreate (event) {
            this.onDragToCreate(event)
            this.deleteEventFunction()
        },
    },
}
</script>
<style lang="stylus">
#vuecal
    &.vuecal--day-view,&.vuecal--week-view
        height:720px
    .product-tag.product-tag__addional-info.product-tag--full
        padding: 0 2px
        display: inline
        margin-right: 4px
.vuecal--month-view .vuecal__cell {height: 80px;}

.vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {padding: 4px;}
.vuecal--month-view .vuecal__no-event {display: none;}

// .vuecal--short-events .vuecal__event-title
//
.vuecal__event-content
    display:inline
.vuecal--month-view
    .vuecal__event-title
        font-size: 14px
        font-weight: 700
    .vuecal__event-time
        font-size: 14px
        margin-left: 8px

.vuecal__event-title
    font-size: 16px
    font-weight: 700
.vuecal__event-time
    font-size: 16px
    margin-left: 8px
// .vuecal__cell .cell-time-labels
//     background-color:$disable-bgcolor
.vuecal--month-view .vuecal__cell
    height: auto
    min-height:80px
// .vuecal__cell:before
//     border-right: 4px solid rgba(196,196,196,1)
//     border-left: 4px solid rgba(196,196,196,1)
//     right: -8px
    // bottom: -1px
    // z-index: 2
.vuecal__event
    background-color: alpha($yellow-color, 0.7);
    &.view-more
        color: $yellow-color
        background-color: $yellow-light-color ;
        small
            font-size: 12px
            font-weight: 400
.vuecal__cell--today, .vuecal__cell--current
    background-color: $yellow-light-color;
    border: 1px solid rgba(255,218,46,1);
    box-shadow: 0 0 4px rgba(255,218,46,1);
    .vuecal__cell-date
        background-color: red;
        // background_primary-gradient-color()
        color: #fff;
        border-radius: 100px
        padding: 4px 8px
.vuecal__title--today_tag
    background-color: red;
    // background_primary-gradient-color()
    color: #fff;
    border-radius: 100px
    padding: 4px 8px
    margin-left: 4px
</style>
