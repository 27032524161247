var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-tag" },
    [
      _vm.product.eventType === _vm.EventType.RESERVATION ||
      _vm.product.type === _vm.ProductType.RESERVE
        ? _c(
            "div",
            {
              staticClass: "product-card__type product-card__reservation-type"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("formatTxType")(
                      _vm.product.eventType || _vm.product.type
                    )
                  ) +
                  "\n    "
              )
            ]
          )
        : _c("BoostBuffetOrPartyEventTag", { attrs: { product: _vm.product } }),
      _vm.product.noSoldItem >= _vm.product.totalImportToStock
        ? _c(
            "div",
            {
              staticClass:
                "product-tag product-tag__addional-info product-tag--full"
            },
            [
              _c(
                "span",
                [
                  _c("a-icon", { attrs: { type: "check" } }),
                  _vm._v("\n            จ่ายครบแล้ว\n        ")
                ],
                1
              )
            ]
          )
        : _vm.product.noCheckedOutItem >= _vm.product.totalImportToStock
        ? _c(
            "div",
            {
              staticClass:
                "product-tag product-tag__addional-info product-tag--complete"
            },
            [
              _c(
                "span",
                [
                  _c("a-icon", { attrs: { type: "lock" } }),
                  _vm._v("\n            จองเต็มแล้ว\n        ")
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }