<template>
    <div>
        <VModal
            v-model="visibleCreateForm"
            title="เพิ่มลูกค้า">
            <ProductLocalCustomerCreateForm :max-people="maxPeople" @success="onCreateEntity" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขข้อมูลลูกค้า">
            <ProductLocalCustomerUpdateForm
                :data="selectedEntity"
                :max-people="maxPeople"
                @success="onUpdate" />
        </VModal>
        <h3>ลูกค้าจากนอกระบบ</h3>
        <a-button
            type="primary"
            class="product-local-customers__action-button"
            @click="onClickCreate">
            เพิ่มลูกค้า
        </a-button>
        <VTable
            :data-source="entities"
            flexible-width="240px"
            :columns="columns"
            :loading="loading"
            :custom-row="createClickToEditRow">
            <template
                v-slot:isPaid="{ record }">
                <a-checkbox
                    v-model="record.isPaid"
                    @click.stop />
            </template>
            <template
                v-slot:numberOfPeople="{ text }">
                {{ text }} <a-icon type="team" />
            </template>
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="() => onDelete(record.id)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>
<script>
import ProductLocalCustomerCreateForm from '@/components/ProductLocalCustomerCreateForm.vue'
import ProductLocalCustomerUpdateForm from '@/components/ProductLocalCustomerUpdateForm.vue'
import VModal from '@/components/VModal.vue'
import VTable from '@/components/VTable.vue'
import VApiResponse from '@/utils/VApiResponse'
import FormItems from '@/mixins/FormItems'
import MixinTableLocalCustomers from '@/mixins/MixinTableLocalCustomers'

export default {
    components: {
        ProductLocalCustomerCreateForm,
        ProductLocalCustomerUpdateForm,
        VModal,
        VTable,
    },
    mixins: [MixinTableLocalCustomers, FormItems],
    props: {
        maxPeople: {
            type: Number,
            required: true,
        },
    },
    data () {
        return {
            nextGeneratedId: 0,
        }
    },
    watch: {
        entities (newValue) {
            this.form.setFieldsValue({
                localCustomers: newValue,
            })
        },
    },
    beforeMount () {
        this.form.getFieldDecorator('localCustomers', { initialValue: [], preserve: true })
    },
    methods: {
        getMaxPeople () {
            return this.maxPeople
        },
        getEntities () {
            return this.dataSource
        },
        fetchEntities () {
            return VApiResponse.SUCCESS([])
        },
        async delete (customerId) {
            this.updateDataSource(this.dataSource.filter((e) => e.id !== customerId))
            return VApiResponse.SUCCESS(true)
        },
        async create (data) {
            this.updateDataSource([...this.entities, { id: this.retriveGeneratedId(), ...data }])
            return VApiResponse.SUCCESS(data)
        },
        async update (data) {
            const updateEntities = this.dataSource.map(
                (item) => (
                    item.id !== data.id
                        ? item
                        : { ...item, ...data }),
            )
            this.updateDataSource(updateEntities)
            return VApiResponse.SUCCESS(data)
        },
        retriveGeneratedId () {
            const id = this.nextGeneratedId
            this.nextGeneratedId += 1
            return id
        },
    },
}
</script>
<style lang="stylus">
.product-local-customers__action-button
    float: right
</style>
