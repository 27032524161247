<template>
    <div class="field-price-rule-create-form">
        <a-alert
            :style="{ marginBottom: '16px' }"
            message="ต้องกดบันทึกแล้วเท่านั้น การแก้ไขถึงจะสำเร็จ"
            banner
            closable />
        <h3>เพิ่ม กฎราคา ใหม่</h3>
        <a-form :form="form" @submit="handleSubmit">
            <a-form-item
                label="วัน"
                :validate-status="getValidateDays()"
                :help="getHelpMsgDays()"
                v-bind="formItemLayout">
                <a-checkable-tag
                    v-for="day in weekDays"
                    :key="day"
                    :checked="selectedDays.indexOf(day) > -1"
                    @change="(checked) => handleWeekDayChange(day, checked)">
                    {{ day | formatWeekdayEnum }}
                </a-checkable-tag>
            </a-form-item>
            <a-form-item
                v-for="(k, index) in form.getFieldValue('keys')"
                :key="k"
                v-bind="index === 0 ? formItemLayout : formItemLayoutWithOutLabel"
                :label="index === 0 ? 'ช่วงเวลา' : ''"
                has-feedback
                class="field-price-rule-create-form__range-time"
                :required="false">
                <VFormItemRangeTime
                    :shink="true"
                    :form="form"
                    :minute-step="15"
                    :is-prevent-start-before-end="true"
                    :name-prop-start-time="`times[${k}][0]`"
                    :name-prop-end-time="`times[${k}][1]`">
                    <a-icon
                        v-if="form.getFieldValue('keys').length >= 1 "
                        class="dynamic-delete-button"
                        type="minus-circle-o"
                        @click="() => remove(k)" />
                </VFormItemRangeTime>
                <!-- <a-form-item :style="{ display: 'inline-block',marginBottom: '0' }">
                    <a-time-picker
                        v-decorator="[ `times[${k}][0]`,
                                       { rules:
                                           [
                                               { required: true,
                                                 message: 'Required', } ] } ]"
                        :minute-step="15"
                        format="HH:mm"
                        placeholder="Start Time"
                        :default-open-value="moment('00:00:00', 'HH:mm:ss')" />
                </a-form-item>
                -
                <a-form-item :style="{ display: 'inline-block',marginBottom: '0' }">
                    <a-time-picker
                        v-decorator="
                            [ `times[${k}][1]` ,
                              { rules:
                                  [
                                      { required: true,
                                        message: 'Required or remove this line.', } ] } ]"
                        :minute-step="15"
                        format="HH:mm"
                        placeholder="End Time"
                        :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                        has-feedback="has-feedback"
                        :disabled="!isSetStartTime(k)"
                        :disabled-hours="()=>getHourBeforeStartTime(k)"
                        :disabled-minutes="(selectedHour)=>getMinuteBeforeStartTime(selectedHour,k)"
                        @blur="closeTimePickerDirty = true" />
                </a-form-item> -->

            </a-form-item>
            <a-form-item v-bind="formItemLayoutWithOutLabel">
                <a-button
                    type="dashed"
                    style="width: 60%"
                    @click="add">
                    <a-icon type="plus" /> เพิ่มช่วงเวลา
                </a-button>
            </a-form-item>
            <a-form-item
                v-bind="formItemLayout"
                label="ราคา">
                <a-input-number v-decorator="[ 'price' ]" :min="0" /> บาท/ชม.
            </a-form-item>
            <a-form-item v-bind="formItemLayoutWithOutLabel">
                <a-button html-type="submit">
                    เพิ่ม
                </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>
<script>
import moment from 'moment'
import Form from '@/mixins/Form'
import { convertToRruleWeekDay, getAllWeekDays, getFirstRangeTimesFromRrule } from '@/utils/utils'
import VFormItemRangeTime from '@/components/VFormItemRangeTime.vue'
import { RRule } from 'rrule'
import MixinFieldPriceRule from '@/mixins/MixinFieldPriceRule'

export default {
    components: {
        VFormItemRangeTime,
    },
    mixins: [Form, MixinFieldPriceRule],
    data () {
        return {
            moment,
            formDirty: false,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 2 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 22 },
                },
            },
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                    xs: { span: 24, offset: 0 },
                    sm: { span: 22, offset: 2 },
                },
            },
            selectedDays: [],
            weekDays: getAllWeekDays(),
            nextDynamicFormId: 0,
        }
    },
    mounted () {
        this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
    },
    methods: {
        getValidateDays () {
            if (this.formDirty && this.selectedDays.length <= 0) return 'error'
            return ''
        },
        getHelpMsgDays () {
            if (this.formDirty && this.selectedDays.length <= 0) return 'Must be select at least one item.'
            return ''
        },
        getHelpMsgRangeTime (rowKey) {
            if (this.getValidateStatusRangeTime(rowKey) === 'error') {
                return 'Start time must come Before End time.'
            }
            return null
        },
        getValidateStatusRangeTime (rowKey) {
            if (
                !this.form.getFieldValue(`times[${rowKey}][0]`)
                || !this.form.getFieldValue(`times[${rowKey}][1]`)
            ) return ''
            if (
                this.form.getFieldValue(`times[${rowKey}][0]`)
                    .isBefore(this.form.getFieldValue(`times[${rowKey}][1]`))
            ) {
                return 'success'
            }
            return 'error'
        },
        validateRangeTime (start, end) {
            if (
                !start
                || !end
            ) return null
            if (
                start
                    .isBefore(end)
            ) {
                return true
            }
            return false
        },
        getHourBeforeStartTime (rowKey) {
            const startHour = this.form.getFieldValue(`times[${rowKey}][0]`).get('H')
            const before = []
            for (let i = 0; i < startHour; i += 1) {
                before.push(i)
            }
            return before
        },
        getMinuteBeforeStartTime (selectedHour, rowKey) {
            const startHour = this.form.getFieldValue(`times[${rowKey}][0]`).get('H')
            if (startHour === selectedHour) {
                const startMinute = this.form.getFieldValue(`times[${rowKey}][0]`).get('m')
                const before = []
                for (let i = 0; i <= startMinute; i += 15) {
                    before.push(i)
                }
                return before
            }
            return []
        },
        isSetStartTime (rowKey) {
            return this.form.getFieldValue(`times[${rowKey}][0]`)
        },
        handleWeekDayChange (day, checked) {
            const newSelectedDays = checked
                ? [...this.selectedDays, day]
                : this.selectedDays.filter((item) => item !== day)
            // console.log('You are interested in: ', nextSelectedTags);
            this.selectedDays = newSelectedDays
        },
        remove (k) {
            const { form } = this
            // can use data-binding to get
            const keys = form.getFieldValue('keys')
            // can use data-binding to set
            form.setFieldsValue({
                keys: keys.filter((key) => key !== k),
            })
        },
        add () {
            const { form } = this
            // can use data-binding to get
            const keys = form.getFieldValue('keys')
            const nextKeys = keys.concat(this.nextDynamicFormId)
            this.nextDynamicFormId += 1
            // can use data-binding to set
            // important! notify form to detect changes
            form.setFieldsValue({
                keys: nextKeys,
            })
        },
        async submit (values) {
            this.formDirty = true
            if (this.selectedDays.length <= 0) return false
            const times = this.form.getFieldValue('times')
            if (times) {
                for (let i = 0; i < times.length; i += 1) {
                    if (this.getValidateStatusRangeTime(i) === 'error') return false
                }
            }
            const originalRules = this.data.priceRules
            const newRules = this.extractValuesToRules({ ...values, days: this.selectedDays })
            const mergedRules = this.applyNewRules(originalRules, newRules)
            const { onCreateRule } = this
            if (this.hasOverrideRules(originalRules, mergedRules)) {
                return new Promise((resolve) => {
                    this.$confirm({
                        title: 'ยืนยันการเพิ่มราคา?',
                        content: (
                            <div>
                                <p>มีบางช่วงเวลาที่มีราคาอยู่แล้ว จะถูกบันทึกทับ</p>
                            </div>
                        ),
                        centered: true,
                        onOk () {
                            return resolve(onCreateRule(newRules))
                        },
                        onCancel () {
                            return resolve(false)
                        },
                    })
                }).catch(() => {})
            }
            return onCreateRule(newRules)
        },
        onCreateRule (rules) {
            this.$emit('create', rules)
            this.resetForm()
            return true
        },
        resetForm () {
            this.form.resetFields(['keys', 'price', 'times'])
            this.selectedDays = []
            this.formDirty = false
        },
        extractValuesToRules (values) {
            const result = []
            values.days.forEach((day) => {
                if (values.times) {
                    values.times.filter((time) => this.validateRangeTime(time[0], time[1])).forEach((time) => {
                        const rrule = new RRule({
                            freq: RRule.WEEKLY,
                            byhour: time[0].hour(),
                            byminute: time[0].minute(),
                            bysecond: 0,
                            byweekday: [convertToRruleWeekDay(day)],
                            tzid: 'Asia/Bangkok',
                        })
                        const minutesDuration = Math.ceil(moment.duration(time[1].diff(time[0])).asMinutes())
                        result.push({
                            weekDay: day,
                            rrule,
                            minutesDuration,
                            rangeTimeString: [this.extractTime(time[0]), this.extractTime(time[1])],
                            rangeTime: getFirstRangeTimesFromRrule(rrule, minutesDuration),
                            price: values.price,
                        })
                    })
                    return
                }
                result.push({
                    weekDay: day,
                    rangeTime: null,
                    price: values.price,
                })
            })
            return result
        },
        extractTime (time) {
            return `${time.hour()}:${time.minute()}`
        },
    },
}
</script>
<style lang="stylus">
.field-price-rule-create-form
    clear: both
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 0px;
  font-size: 16px;
  color: $primary-color
  transition: all 0.3s;
  margin-left: 32px
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.field-price-rule-create-form__range-time .ant-form-item-children-icon
    right: 16px !important
.field-price-rule-create-form
    .has-feedback .ant-calendar-picker-icon
    , form .has-feedback .ant-time-picker-icon
    , form .has-feedback .ant-calendar-picker-clear
    , form .has-feedback .ant-time-picker-clear
        right 11px
</style>
