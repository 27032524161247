var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c("MemberTypes", {
        attrs: {
          "disable-click-to-edit-row": true,
          "data-source": _vm.membershipDataSource,
          loading: _vm.currentMemberLoading
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                record
                  ? _c(
                      "a-popconfirm",
                      {
                        on: {
                          confirm: function() {
                            return _vm.onRemove()
                          }
                        }
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c("h4", [_vm._v("ยืนยัน การยกเลิกสมาชิก")]),
                          _c(
                            "p",
                            [
                              _vm._v(
                                "\n                        เหตุผล:\n                        "
                              ),
                              _c("a-textarea", {
                                attrs: {
                                  placeholder: "เหตุผล การยกเลิก",
                                  "auto-size": "",
                                  value: _vm.reasonsToRemove
                                },
                                on: { change: _vm.onChangereasonsToRemove }
                              })
                            ],
                            1
                          )
                        ]),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ยกเลิก")]
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }