import ModelExtractedPriceRule from '@/models/ModelExtractedPriceRule'
import ModelTimeSlot from '@/models/ModelTimeSlot'
import { includeText } from '@/utils/utils'
import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const searchProduct = (products, query) => {
    if (!query) return [...products]
    const matches = products.filter(
        (item) => includeText(item.name, query)
            || (item.start
                && includeText(item.start.format('DD-MM-YYYY'), query)),
    )
    return matches
}
function _getPriceAt (
    dateTime, extractedRules,
) {
    try {
        const activeSlot = extractedRules
            .find((extractedRule) => extractedRule.isActiveAt(dateTime))
        return activeSlot.price
    } catch (e) {
        return null
    }
}
export const estimateFieldPrice = (_startTime, _endTime, _rules) => {
    const extractedPriceRule = _rules.reduce(
        (extractedRules, rule) => {
            const { rrule } = rule
            const dateTimesOfRule = rrule.between(
                _startTime.utc(true).toDate(),
                _endTime.utc(true).toDate(), true,
            )
            return [
                ...extractedRules,
                ...dateTimesOfRule
                    .map((dt) => moment(dt).local(true))
                    .map((dt) => new ModelExtractedPriceRule({
                        price: rule.price,
                        start: dt,
                        endBefore: dt.clone().add(rule.minutesDuration, 'minutes'),
                    }))]
        }, [],
    )
    const slots = ModelTimeSlot.fromTimeRange(
        _startTime, _endTime, 15,
    )
    const priceSlots = slots.map((slot) => new ModelTimeSlot(
        {
            start: slot.start,
            end: slot.end,
            price: _getPriceAt(slot.start, extractedPriceRule),
        },
    ))

    const sumPrice = priceSlots.reduce((sum, next) => sum + next.price, 0)
    return sumPrice
}
