var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("ลูกค้าจาก Lenkila")]),
      _c(
        "a-tabs",
        { attrs: { type: "card" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "ใช้งานได้" } },
            [
              _c("TableProductCustomers", {
                attrs: { id: _vm.id, "is-active": true }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "ยกเลิกไปแล้ว" } },
            [
              _c("TableProductCustomers", {
                attrs: { id: _vm.id, "is-active": false }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }