var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VForm", {
    staticClass: "customer-create-form",
    attrs: { "on-submit-async": _vm.submit },
    on: {
      success: function(value) {
        return this$1.$emit("success", value)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var form = ref.form
          return [
            _c("PeopleCreateAndUpdateFormItems", {
              attrs: {
                form: form,
                "action-type": "create",
                "enable-address-input": false,
                "enable-note-input": true
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }