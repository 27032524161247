var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tabs",
    { attrs: { "default-active-key": "1" } },
    [
      _c(
        "a-tab-pane",
        { key: "1", attrs: { tab: "ทั่วไป" } },
        [
          _c("VForm", {
            attrs: { data: _vm.field, "on-submit-async": _vm.submit },
            on: {
              success: function(value) {
                return this$1.$emit("success", value)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var form = ref.form
                  var data = ref.data
                  return [
                    _c("StadiumFieldCreateAndUpdateFormItems", {
                      attrs: { form: form, data: data }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-tab-pane",
        { key: "2", attrs: { tab: "ตารางราคา" } },
        [_c("FieldPrice", { attrs: { "field-id": _vm.fieldId } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }