var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "member-types-update-form",
      attrs: { form: _vm.form, layout: "horizontal" },
      on: { submit: _vm.handleSubmit }
    },
    [
      _c("MemberTypeCreateAndUpdateFormItems", {
        attrs: { form: _vm.form, data: _vm.memberType }
      }),
      _c(
        "a-form-item",
        {
          style: { marginBottom: 0 },
          attrs: { "wrapper-col": { span: 12, offset: 4 } }
        },
        [
          _c(
            "a-button",
            {
              staticClass: "member-types-update-form__save-button",
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.isProcessing
              }
            },
            [_vm._v("\n            Save\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }