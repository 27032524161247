var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อสนาม"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'name',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ]
          })
        ],
        1
      ),
      _c("VFormItemCoverPhoto", { attrs: { form: _vm.form, data: _vm.data } }),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "คำบรรยายเบื้องต้น"
          }
        },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["description"],
                expression: "[ 'description' ]"
              }
            ],
            attrs: {
              placeholder:
                "ใส่คำอธิบายเกี่ยวกับสนาม เช่น สนามฟุตบอลขนาดเล็ก อยู่ในร่ม",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภทกีฬา"
          }
        },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "sportId",
                    {
                      rules: [
                        { required: true, message: "กรุณาเลือกกีฬาด้วยค่ะ" }
                      ]
                    }
                  ],
                  expression:
                    "[ 'sportId',\n                           { rules:\n                               [\n                                   { required: true,\n                                     message: 'กรุณาเลือกกีฬาด้วยค่ะ', }\n                               ] } ]"
                }
              ],
              staticStyle: { width: "240px" },
              attrs: {
                "show-search": "",
                placeholder: "เลือกประเภทกีฬา",
                "option-filter-prop": "children",
                "filter-option": _vm.filterOption
              }
            },
            _vm._l(_vm.sports, function(sport) {
              return _c(
                "a-select-option",
                { key: sport.id, attrs: { value: sport.id } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(sport.displayName) +
                      "\n            "
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "แบ่งขายได้"
          }
        },
        [
          _c("a-checkbox", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "dividable",
                  {
                    initialValue: false,
                    valuePropName: "checked"
                  }
                ],
                expression:
                  "[ 'dividable',{\n                initialValue: false,valuePropName: 'checked' } ]"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }