<template>
    <div>
        <VModal
            v-model="visibleCreateForm"
            title="เพิ่มลูกค้า">
            <StadiumBoostInsertCustomerForm :boost-id="id" @success="closeCreateForm" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขข้อมูลลูกค้า">
            <StadiumBoostUpdateCustomerForm
                :boost-id="id"
                :customer-id="selectedEntityId"
                @success="closeUpdateForm" />
        </VModal>
        <h3>ลูกค้าจากนอกระบบ</h3>
        <a-button
            type="primary"
            class="product-local-customers__action-button"
            @click="onClickCreate">
            เพิ่มลูกค้า
        </a-button>
        <VTable
            :data-source="dataSource"
            flexible-width="240px"
            :columns="columns"
            :loading="loading"
            :custom-row="createClickToEditRow">
            <template
                v-slot:memberType="{ text,record }">
                <Member
                    :product-id="id"
                    :tx-item="record"
                    :model="text" />
            </template>
            <template
                v-slot:isPaid="{ record }">
                <a-checkbox
                    :checked="record.isPaid"
                    @click.stop
                    @change="(e)=>onIsPaidChange(e,record)" />
            </template>
            <template
                v-slot:numberOfPeople="{ text }">
                {{ text }} <a-icon type="team" />
            </template>
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="() => onDelete(record.id)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import StadiumBoostInsertCustomerForm from '@/components/StadiumBoostInsertCustomerForm.vue'
import StadiumBoostUpdateCustomerForm from '@/components/StadiumBoostUpdateCustomerForm.vue'
import VModal from '@/components/VModal.vue'
import VTable from '@/components/VTable.vue'
import MixinTableLocalCustomers from '@/mixins/MixinTableLocalCustomers'
import Member from '@/components/Member.vue'

export default {
    components: {
        StadiumBoostInsertCustomerForm,
        StadiumBoostUpdateCustomerForm,
        VModal,
        VTable,
        Member,
    },
    mixins: [MixinTableLocalCustomers],
    props: {
        id: {
            type: String,
            required: true,
        },
        enableManagerMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        boost () {
            return this.$store.getters[Getters.products.GET_PRODUCT](this.id)
        },
    },
    methods: {
        getMaxPeople () {
            return this.boost.totalImportToStock
        },
        getEntities () {
            return this.boost.localCustomers
        },
        fetchEntities () {
            return this.$store.dispatch(rootActions.LOAD_PRODUCT_LOCAL_CUSTOMERS, this.id)
        },
        async delete (customerId) {
            const { id } = this
            return this.$store.dispatch(
                rootActions.DELETE_PRODUCT_LOCAL_CUSTOMER,
                { boostId: id, customerId },
            )
        },
        onIsPaidChange (e, record) {
            this.$store.dispatch(
                rootActions.UPDATE_PRODUCT_LOCAL_CUSTOMER_IS_PAID,
                { boostId: this.id, customer: { ...record, isPaid: e.target.checked } },
            )
        },
    },
}
</script>
<style lang="stylus">
.product-local-customers__action-button
    float: right
</style>
