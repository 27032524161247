<template>
    <div>
        <h2>{{ title }}</h2>
        <MemberTypes
            :disable-click-to-edit-row="true"
            :data-source="membershipDataSource"
            :loading="currentMemberLoading">
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="record"
                    @confirm="() => onRemove()">
                    <template slot="title">
                        <h4>ยืนยัน การยกเลิกสมาชิก</h4>
                        <p>
                            เหตุผล:
                            <a-textarea
                                placeholder="เหตุผล การยกเลิก"
                                auto-size
                                :value="reasonsToRemove"
                                @change="onChangereasonsToRemove" />
                        </p>
                    </template>
                    <a href="javascript:;" @click.stop>ยกเลิก</a>
                </a-popconfirm>
            </template>
        </MemberTypes>
    </div>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import MemberTypes from '@/components/MemberTypes.vue'

export default {
    components: {
        MemberTypes,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: 'สิทธิที่ใช้ในการซื้อครั้งนี้',
        },
        txItem: {
            type: Object,
            required: true,
        },
        productId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            currentMemberLoading: false,
            reasonsToRemove: undefined,
        }
    },
    computed: {
        customer () {
            return this.$store.getters[Getters.customers.GET_CUSTOMER](this.customerId)
        },
        membershipDataSource () {
            return this.txItem.stadiumMembers ? this.txItem.stadiumMembers : []
        },
    },
    methods: {
        onChangereasonsToRemove (e) {
            this.reasonsToRemove = e.target.value
        },
        async onRemove () {
            this.currentMemberLoading = true
            const { productId } = this
            const newTxItem = { ...this.txItem, stadiumMemberIds: [] }
            const res = await this.$store.dispatch(
                rootActions.UPDATE_PRODUCT_LOCAL_CUSTOMER,
                { boostId: productId, customer: newTxItem },
            )
            if (res.isSuccess()) {
                this.$emit('success', res.data)
            }
            this.currentMemberLoading = false
        },
    },
}
</script>
