<template>
    <div>
        <VModal
            v-model="visibleCreateForm"
            title="สร้างการโปรโมท">
            <StadiumBoostCreateForm :data="boost" @success="handleCreateBoost" />
        </VModal>
        <a-row>
            <a-col flex="auto" class="stadium-boosts_action-button">
                <a-button icon="copy" @click="openCreateForm">
                    สร้างซ้ำ
                </a-button>
            </a-col>
        </a-row>
        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="ข้อมูลทั่วไป">
                <VForm
                    v-slot="{ form,data }"
                    :disabled="disableUpdate"
                    :on-delete-async="deleteProduct"
                    :data="boost"
                    :on-submit-async="submit"
                    @success="(value)=>this.$emit('success',value)">
                    <StadiumBoostCreateAndUpdateFormItems
                        :disable-update="disableUpdate"
                        :is-update-form="true"
                        :data="data"
                        :form="form" />
                </VForm>
            </a-tab-pane>
            <a-tab-pane
                key="2"
                tab="ผู้เข้าร่วม"
                :force-render="false">
                <ProductCustomers :id="boostId" />
                <br>
                <ProductLocalCustomers :id="boostId" />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import StadiumBoostCreateAndUpdateFormItems from '@/components/StadiumBoostCreateAndUpdateFormItems.vue'
import ProductCustomers from '@/components/ProductCustomers.vue'
import VForm from '@/components/VForm.vue'
import { rootActions, Getters } from '@/store/types'
import Form from '@/mixins/Form'
import ProductLocalCustomers from '@/views/ProductLocalCustomers.vue'
import VModal from '@/components/VModal.vue'
import StadiumBoostCreateForm from '@/components/StadiumBoostCreateForm.vue'
import MixinCreateFormModal from '@/mixins/MixinCreateFormModal'
import MixinProduct from '@/mixins/MixinProduct'

export default {
    components: {
        StadiumBoostCreateForm,
        StadiumBoostCreateAndUpdateFormItems,
        ProductLocalCustomers,
        ProductCustomers,
        VForm,
        VModal,
    },
    mixins: [Form, MixinCreateFormModal, MixinProduct],
    props: {
        boostId: {
            type: String,
            required: true,
        },
        disableUpdate: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        boost () {
            if (this.data) return this.data
            return this.$store.getters[Getters.products.GET_PRODUCT](this.boostId)
        },
        product () {
            return this.boost
        },
    },
    methods: {
        handleCreateBoost (entity) {
            this.$emit('duplicate', entity)
            this.closeCreateForm()
        },
        async submit (values) {
            const { variants, ...others } = values
            const res = await this.$store.dispatch(
                rootActions.UPDATE_PRODUCT, {
                    ...others,
                    variants: variants ? variants
                        .filter((el) => el != null) : undefined,
                    id: this.boost.id,
                    type: this.boost.type,
                },
            )
            return res
        },
    },
}
</script>
<style lang="stylus">
.stadium-boosts_action-button
    float right
    clear both
</style>
