var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    [
      _c("h3", [_vm._v("รูปแบบ การแสดงผล")]),
      _c(
        "a-radio-group",
        {
          attrs: { value: _vm.selectedViewMode, "button-style": "solid" },
          on: { change: _vm.handleViewModeChange }
        },
        [
          _c(
            "a-radio-button",
            { attrs: { value: _vm.ViewMode.GRID } },
            [_c("a-icon", { attrs: { type: "table" } })],
            1
          ),
          _c(
            "a-radio-button",
            { attrs: { value: _vm.ViewMode.CALENDAR } },
            [_c("a-icon", { attrs: { type: "calendar" } })],
            1
          )
        ],
        1
      ),
      _vm.selectedViewMode === _vm.ViewMode.CALENDAR
        ? _vm._t("calendarEvent")
        : _c(
            "div",
            { staticClass: "stadium-boosts" },
            [
              _c(
                "a-tabs",
                { attrs: { "default-active-key": "1" } },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "tab" }, slot: "tab" },
                        [
                          _c("a-icon", { attrs: { type: "double-right" } }),
                          _vm._v(
                            "\n                    กำลังจะมาถึง\n                "
                          )
                        ],
                        1
                      ),
                      _vm._t("incomingEvent")
                    ],
                    2
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "tab" }, slot: "tab" },
                        [
                          _c("a-icon", { attrs: { type: "history" } }),
                          _vm._v(
                            "\n                    ประวัติ\n                "
                          )
                        ],
                        1
                      ),
                      _vm._t("historyEvent")
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }