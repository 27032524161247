var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างการโปรโมท" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("StadiumBoostCreateForm", {
            attrs: { data: _vm.boost },
            on: { success: _vm.handleCreateBoost }
          })
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            {
              staticClass: "stadium-boosts_action-button",
              attrs: { flex: "auto" }
            },
            [
              _c(
                "a-button",
                { attrs: { icon: "copy" }, on: { click: _vm.openCreateForm } },
                [_vm._v("\n                สร้างซ้ำ\n            ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "ข้อมูลทั่วไป" } },
            [
              _c("VForm", {
                attrs: {
                  disabled: _vm.disableUpdate,
                  "on-delete-async": _vm.deleteProduct,
                  data: _vm.boost,
                  "on-submit-async": _vm.submit
                },
                on: {
                  success: function(value) {
                    return this$1.$emit("success", value)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var form = ref.form
                      var data = ref.data
                      return [
                        _c("StadiumBoostCreateAndUpdateFormItems", {
                          attrs: {
                            "disable-update": _vm.disableUpdate,
                            "is-update-form": true,
                            data: data,
                            form: form
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "ผู้เข้าร่วม", "force-render": false } },
            [
              _c("ProductCustomers", { attrs: { id: _vm.boostId } }),
              _c("br"),
              _c("ProductLocalCustomers", { attrs: { id: _vm.boostId } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }