<template>
    <div class="product-tag">
        <div
            v-if=" product.eventType === EventType.RESERVATION
                || product.type === ProductType.RESERVE"
            class="product-card__type product-card__reservation-type">
            {{ product.eventType || product.type | formatTxType }}
        </div>
        <BoostBuffetOrPartyEventTag
            v-else
            :product="product" />
        <div
            v-if="product.noSoldItem >= product.totalImportToStock"
            class="product-tag product-tag__addional-info product-tag--full">
            <span>
                <a-icon type="check" />
                จ่ายครบแล้ว
            </span>
        </div>
        <div
            v-else-if="product.noCheckedOutItem >= product.totalImportToStock"
            class="product-tag product-tag__addional-info product-tag--complete">
            <span>
                <a-icon type="lock" />
                จองเต็มแล้ว
            </span>
        </div>

    </div>
</template>
<script>
import { ProductType, EventType } from '@/utils/enum'
import BoostBuffetOrPartyEventTag from '@/components/BoostBuffetOrPartyEventTag.vue'

export default {
    components: {
        BoostBuffetOrPartyEventTag,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ProductType,
            EventType,
        }
    },
}
</script>
<style lang="stylus">
.product-tag
    position: relative
    color: white

.product-card__reservation-type
    background: $green-color
    color: white
    text-align: center

.product-tag-addional-info
    padding: 0 0 0 8px

.product-tag.product-tag__addional-info
    padding: 4px 8px

.product-tag.product-tag--full
    background_primary-gradient-color()
.product-tag.product-tag--complete
    background: $primary-color
    padding: 4px 8px
</style>
