<template>
    <VForm
        v-slot="{ form }"
        class="member-types-create-form"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <MemberTypeCreateAndUpdateFormItems :form="form" action-type="create" />
    </VForm>
</template>
<script>
import MemberTypeCreateAndUpdateFormItems from '@/components/MemberTypeCreateAndUpdateFormItems.vue'
import Form from '@/mixins/Form'
import { rootActions } from '@/store/types'
import VForm from '@/components/VForm.vue'

export default {
    components: {
        MemberTypeCreateAndUpdateFormItems,
        VForm,
    },
    mixins: [Form],
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_MEMBER_TYPE, { ...values },
            )
            return res
        },
    },
}
</script>
