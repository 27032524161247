var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "แก้ไขการโปรโมท" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("StadiumBoostUpdateForm", {
            attrs: {
              "disable-update": false,
              "boost-id": _vm.selectedEntityId
            },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c("VTable", {
        attrs: {
          "enable-scroll": true,
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          loading: _vm.loading,
          pagination: _vm.shink
            ? Object.assign({}, _vm.pagination, { position: "bottom" })
            : _vm.pagination,
          "on-refresh": _vm.onRefresh
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "numberOfPeople",
            fn: function(ref) {
              var record = ref.record
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(record.mirrorVariant.amountPerUnit * record.amount) +
                    " "
                ),
                _c("a-icon", { attrs: { type: "team" } }),
                _c("br"),
                _vm._v(
                  "\n            ( " +
                    _vm._s(record.amount) +
                    "x" +
                    _vm._s(record.mirrorVariant.amountPerUnit) +
                    " คน)\n        "
                )
              ]
            }
          },
          {
            key: "amountPerUnit",
            fn: function(ref) {
              var text = ref.text
              return [_vm._v("\n            " + _vm._s(text) + " คน\n        ")]
            }
          },
          {
            key: "paymentMethod",
            fn: function(ref) {
              var text = ref.text
              var record = ref.record
              return [
                _c(
                  "a-tag",
                  {
                    attrs: {
                      color:
                        text === _vm.PaymentMethod.PAY_AT_STADIUM
                          ? "blue"
                          : "orange"
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.getMsgFromPaymentMethod(text).toUpperCase()
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _c(
                  "a-tag",
                  { attrs: { color: _vm.getColorOfStage(record.stage) } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.getMsgFromTxItemStage(record.stage).toUpperCase()
                        ) +
                        "\n            "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                _vm.dataSource.length && _vm.canCancelStage(record.stage)
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการยกเลิก?" },
                        on: {
                          confirm: function() {
                            return _vm.$emit("cancel", record.id)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ยกเลิก")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.dataSource.length && !_vm.canCancelStage(record.stage)
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-tag",
                          {
                            attrs: { color: _vm.getColorOfStage(record.stage) }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm
                                    .getMsgFromTxItemStage(record.stage)
                                    .toUpperCase()
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "product",
            fn: function(ref) {
              var text = ref.text
              return [
                _c(
                  "a-card",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openUpdateForm(text.id)
                      }
                    }
                  },
                  [
                    _c("ProductTag", { attrs: { product: text } }),
                    _vm._v(
                      "\n                " + _vm._s(text.field.name) + " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("getStartEndPrettyString")(text)) +
                        "\n            "
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }