var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading, delay: 500 } },
    [
      _c("VueCal", {
        ref: "myButton",
        attrs: {
          id: "vuecal",
          "active-view": _vm.activeView,
          "time-cell-height": _vm.timeCellHeight,
          "selected-date": _vm.selectedDate,
          "disable-views": ["years", "year"],
          events: _vm.events,
          time: true,
          "show-time-in-cells": true,
          "snap-to-time": 15,
          "on-event-click": _vm.onClickEvent,
          "editable-events": {
            title: false,
            drag: false,
            resize: false,
            delete: false,
            create: true
          },
          "on-event-create": _vm.onSmartEventCreate,
          "split-days": _vm.splitDays,
          "sticky-split-labels": true
        },
        on: {
          "update:activeView": function(value) {
            return _vm.$emit("update:activeView", value)
          },
          "event-drag-create": _vm.onSmartDragToCreate,
          ready: _vm.onSmartViewChange,
          "view-change": _vm.onSmartViewChange
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var title = ref.title
              var view = ref.view
              return [
                _c(
                  "span",
                  {
                    class: {
                      "vuecal__title--today_tag": view.startDate
                        ? view.startDate.isToday()
                        : false
                    }
                  },
                  [
                    _vm._v("\n                " + _vm._s(title)),
                    view.startDate.isToday()
                      ? _c(
                          "span",
                          { staticClass: "vuecal__title--today_tag" },
                          [_vm._v("วันนี้")]
                        )
                      : _vm._e()
                  ]
                )
              ]
            }
          },
          {
            key: "weekday-heading",
            fn: function(ref) {
              var heading = ref.heading
              var view = ref.view
              return [
                _c(
                  "div",
                  {
                    staticClass: "vuecal__flex weekday-label",
                    class: {
                      "vuecal__title--today_tag": heading.date
                        ? heading.date.isToday()
                        : false
                    },
                    attrs: { grow: "" }
                  },
                  [
                    _c("span", { staticClass: "full" }, [
                      _vm._v(_vm._s(heading.label))
                    ]),
                    _c("span", { staticClass: "small" }, [
                      _vm._v(_vm._s(heading.label.substring(0, 3)))
                    ]),
                    _c("span", { staticClass: "xsmall" }, [
                      _vm._v(_vm._s(heading.label.substring(0, 1)))
                    ]),
                    view.id == "week"
                      ? _c("span", [
                          _vm._v(" " + _vm._s(heading.date.getDate()))
                        ])
                      : _vm._e(),
                    (heading.date
                    ? heading.date.isToday()
                    : false)
                      ? _c(
                          "span",
                          { staticClass: "vuecal__title--today_tag" },
                          [_vm._v("วันนี้")]
                        )
                      : _vm._e()
                  ]
                )
              ]
            }
          },
          {
            key: "cell-content",
            fn: function(ref) {
              var cell = ref.cell
              var view = ref.view
              var _events = ref.events
              var goNarrower = ref.goNarrower
              return [
                !["week", "day"].includes(view.id)
                  ? _c("span", { staticClass: "vuecal__cell-date" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(cell.content) +
                          " " +
                          _vm._s(cell.today ? "วันนี้" : "") +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                ["week", "day"].includes(view.id) && !_events.length
                  ? _c("span", { staticClass: "vuecal__no-event" }, [
                      _vm._v("\n                0 กิจกรรม\n            ")
                    ])
                  : _vm._e(),
                !["week", "day"].includes(view.id)
                  ? _c(
                      "div",
                      { staticClass: "vuecal__cell-events" },
                      _vm._l(_events.slice(0, 3), function(event, index) {
                        return _c(
                          "div",
                          {
                            key: event.id,
                            staticClass: "vuecal__event",
                            class: index < 2 ? event.class : "view-more"
                          },
                          [
                            index < 2
                              ? _c(
                                  "div",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickEvent(event)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vuecal__event-title" },
                                      [
                                        event.noSoldItem >=
                                        event.totalImportToStock
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "product-tag product-tag__addional-info product-tag--full"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("a-icon", {
                                                      attrs: { type: "check" }
                                                    }),
                                                    _vm._v(
                                                      "\n                                    จ่ายครบแล้ว\n                                "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(event.title) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "vuecal__event-content" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              event.noCheckedOutItem
                                                ? event.noCheckedOutItem
                                                : 0
                                            ) +
                                            "\n                            /" +
                                            _vm._s(event.totalImportToStock) +
                                            "\n                            "
                                        ),
                                        _c("a-icon", {
                                          attrs: { type: "team" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "small",
                                      { staticClass: "vuecal__event-time" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              event.start.formatTime("HH:mm")
                                            )
                                          )
                                        ]),
                                        _vm._v(
                                          "-\n                            "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              event.end.formatTime("HH:mm")
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : index == 2
                              ? _c("div", { on: { click: goNarrower } }, [
                                  _c(
                                    "div",
                                    { staticClass: "vuecal__event-title" },
                                    [
                                      _vm._v(
                                        "\n                            + " +
                                          _vm._s(_events.length - 2) +
                                          " กิจกรรม "
                                      ),
                                      _c("small", [
                                        _c("span", [_vm._v("ดูเพิ่มเติม")])
                                      ])
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "event",
            fn: function(ref) {
              var event = ref.event
              var view = ref.view
              return [
                !["week", "day"].includes(view.id)
                  ? _c("div", [
                      _c("div", { staticClass: "vuecal__event-title" }, [
                        event.noSoldItem >= event.totalImportToStock
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "product-tag product-tag__addional-info product-tag--full"
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c("a-icon", { attrs: { type: "check" } }),
                                    _vm._v(
                                      "\n                            จ่ายครบแล้ว\n                        "
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n                    " +
                            _vm._s(event.title) +
                            "\n                "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "vuecal__event-content" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                event.noCheckedOutItem
                                  ? event.noCheckedOutItem
                                  : 0
                              ) +
                              "\n                    /" +
                              _vm._s(event.totalImportToStock) +
                              "\n                    "
                          ),
                          _c("a-icon", { attrs: { type: "team" } })
                        ],
                        1
                      ),
                      _c("small", { staticClass: "vuecal__event-time" }, [
                        _c("span", [
                          _vm._v(_vm._s(event.start.formatTime("HH:mm")))
                        ]),
                        _vm._v("-\n                    "),
                        _c("span", [
                          _vm._v(_vm._s(event.end.formatTime("HH:mm")))
                        ])
                      ])
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "split-label",
            fn: function(ref) {
              var split = ref.split
              return [
                _c("strong", { style: "color: " + split.color }, [
                  _vm._v(_vm._s(split.label))
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }