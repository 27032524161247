<template>
    <div>
        <a-form-item label="สร้างรหัสผ่านใหม่">
            <a-input
                v-decorator="[
                    'password',
                    { rules: [ { required: true,
                                 message: 'กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่' } ] } ]"
                type="password"
                placeholder="รหัสผ่านใหม่">
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0,0,0,.25);" />
            </a-input>
        </a-form-item>
        <a-form-item label="ยืนยัน รหัสผ่านใหม่">
            <a-input
                v-decorator="[
                    'confirmPassword',
                    { rules: [
                        { required: true,
                          message: 'กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่ อีกครั้ง' },
                        { validator: compareToFirstPassword, }
                    ] } ]"
                type="password"
                placeholder="รหัสผ่านใหม่">
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0,0,0,.25);" />
            </a-input>
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    methods: {
        compareToFirstPassword (rule, value, callback) {
            const { form } = this
            if (value && value !== form.getFieldValue('password')) {
                callback('ค่าใส่ช่อง ยืนยัน รหัสผ่านใหม่ ไม่ตรงกับ ช่องรหัสผ่านใหม่ กรุณาแก้ไขให้ตรงกันด้วยค่ะ')
            } else {
                callback()
            }
        },
        mapDataToFormItems () {
            return {}
        },
    },
}
</script>
