<template>
    <div>
        <MemberTypeDetailsModal
            v-model="visibleDetailPage"
            :data="selectedMemberType"
            @close="selectedMemberType = null" />
        <slot name="header" />
        <br>
        <PageMemberTypes
            :borderless="true"
            title="ประเภทสมาชิกในระบบ"
            :title-style="{ fontSize: '18px' }">
            <a-popconfirm
                title="ยืนยันการลบ?"
                @confirm="() => onDelete(record.id)">
                <a
                    href="javascript:;"
                    class="table__operation"
                    @click.stop>ลบ</a>
            </a-popconfirm>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="text,record ">
                <slot
                    v-if="name !== 'header'"
                    :name="name"
                    v-bind="{ text,record }" />
            </template>
        </PageMemberTypes>
    </div>
</template>
<script>
import moment from 'moment'
import { rootActions } from '@/store/types'
import MemberTypeDetailsModal from '@/components/MemberTypeDetailsModal.vue'
import PageMemberTypes from '@/views/PageMemberTypes.vue'

export default {
    components: {
        MemberTypeDetailsModal,
        PageMemberTypes,
    },
    data () {
        return {
            visibleDetailPage: false,
            visibleHistoryPage: false,
            selectedMemberTypeId: null,
            selectedMemberType: null,
            moment,
        }
    },
    methods: {
        getExpiredDate (startDate, expiredInDay) {
            // To Avoid modify original data
            const momentCopy = moment(startDate)
            // console.log(startDate)
            // console.log(expiredInDay)
            return momentCopy.add(expiredInDay, 'days')
        },
        getTextColumn () {
            return this.columns.filter((item) => item.scopedSlots.customRender !== 'color'
            && item.scopedSlots.customRender !== 'duration')
        },
        async onDelete (id) {
            const res = await this.$store.dispatch(rootActions.DELETE_MEMBER_TYPE, id)
            return res
        },
    },
}
</script>
<style lang="stylus">
.member-types__add-button
    margin-bottom: 16px
    float: right
.member-types__member-type-color
    height 32px
.member-types__table
    clear both
.manage-member-form__current-member-type-table
    margin-bottom 16px
    clear both
.manage-member-form__history-button
    margin-bottom: 16px
    float: right
.manage-member-form__remaining-days
    display inline-block
.table__operation
    margin-left:8px
</style>
