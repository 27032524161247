<template>
    <div>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขการโปรโมท">
            <StadiumBoostUpdateForm
                :disable-update="disableUpdate"
                :boost-id="product.id"
                :data="product"
                @duplicate="handleCreateProduct"
                @delete="closeUpdateForm"
                @success="closeUpdateForm" />
        </VModal>
        <VCard
            :disabled="isCanceled"
            :avartar="avatar"
            :title="title"
            :cover-photo-url="coverPhotoUrl"
            :description="product.desc ? product.desc : '-'"
            :cover-photo-ratio="ImageSize.w / ImageSize.h"
            :enable-delete="true"
            @delete="deleteProduct"
            @click.native="()=>enableClickToOpenUpdateForm ? openUpdateForm() : null">
            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name" />
            </template>
            <template slot="cover">
                <div class="product-card__tag">
                    <div
                        v-if="isCancel"
                        class="product-card__type product-card__tag__canceled">
                        <span>
                            <a-icon
                                :type="isCanceling ? 'loading' : 'stop'" />
                            {{ isCanceling ? 'กำลังยกเลิก' : isCanceled ? 'ยกเลิกแล้ว' : 'ยกเลิก' }}
                        </span>
                    </div>
                    <ProductTag :product="product" />
                    <div
                        v-if="product.isReserveLimitReached"
                        class="product-card__type product-card__tag__full">
                        <span>
                            <a-icon type="lock" />
                            จองเต็มแล้ว
                        </span>
                    </div>
                    <div
                        v-if="product.isSoldOut"
                        class="product-card__type product-card__tag__full">
                        <span>
                            <a-icon type="check" />
                            จ่ายครบแล้ว
                        </span>
                    </div>
                </div>
            </template>
            <template slot="overlayBottomCover">
                {{ product | getStartEndPrettyString }}
                <template v-if="defaultVariant">
                    <span v-if="product.eventType === ProductType.BUFFET">
                        {{ defaultVariant.minPrice | formatCurrency }}
                        /คน
                    </span>
                    <span v-else class="product-card__price">
                        {{ defaultVariant.minPrice | formatCurrency }}  /
                        <s class="product-card__full-price">
                            {{ defaultVariant.fullPrice | formatCurrency }}
                        </s>
                    </span>
                </template>
            </template>
        </VCard>
    </div>
</template>
<script>
import { Getters } from '@/store/types'
import {
    EntityImageSize, ProductType, ReservationOfferStatus, EventType, CancelStatus,
} from '@/utils/enum'
import ProductTag from '@/components/ProductTag.vue'
import VCard from '@/components/VCard.vue'
import StadiumBoostUpdateForm from '@/components/StadiumBoostUpdateForm.vue'
import VModal from '@/components/VModal.vue'
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'
import MixinProduct from '@/mixins/MixinProduct'

export default {
    components: {
        VCard,
        ProductTag,
        StadiumBoostUpdateForm,
        VModal,
    },
    mixins: [MixinUpdateFormModal, MixinProduct],
    props: {
        product: {
            type: Object,
            required: true,
        },
        onDelete: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
        disableUpdate: {
            type: Boolean,
            default: false,
        },
        enableClickToOpenUpdateForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            ProductType,
            EventType,
            ImageSize: EntityImageSize.PRODUCT_COVER,
            ReservationOfferStatus,
        }
    },
    computed: {
        isCanceled () {
            return this.product.status
                === ReservationOfferStatus.CANCELED_BY_USER
                || this.product.status === ReservationOfferStatus.CANCELED_BY_STADIUM
                || this.product.cancelStatus === CancelStatus.CANCELED
        },
        isCanceling () {
            return this.product.cancelStatus === CancelStatus.CANCELING
        },
        isCancel () {
            return this.isCanceling || this.isCanceled
        },
        party () {
            return this.$store.getters[Getters.partys.GET_PARTY](this.product.partyId)
        },
        sportIcon () {
            return this.$store.getters[Getters.stadium.GET_SPORT](this.field.sportId)
                ? this.$store.getters[Getters.stadium.GET_SPORT](this.field.sportId).iconUrl
                : null
        },
        title () {
            return this.field.name
        },
        field () {
            if (typeof this.$store.getters[Getters.stadium.GET_FIELD](this.product.fieldId) === 'undefined') return {}
            return this.$store.getters[Getters.stadium.GET_FIELD](this.product.fieldId)
        },
        avatar () {
            if (this.product.type === ProductType.PARTY_EVENT) {
                const { party } = this
                const { logoPhotoUrl } = party
                if (logoPhotoUrl) return logoPhotoUrl
                return null
            }
            return this.sportIcon
        },
        coverPhotoUrl () {
            if (this.product.type === ProductType.PARTY_EVENT) {
                const { party } = this
                const { coverPhotoUrl } = party
                if (coverPhotoUrl) return coverPhotoUrl
            }
            return this.field.coverPhotoUrl
        },
        defaultVariant () {
            if (!this.product.variants) return undefined
            return this.product.variants[0]
        },
    },
    methods: {
        handleCreateProduct (entity) {
            this.$emit('duplicate', entity)
            this.closeUpdateForm()
        },
    },
}
</script>

<style lang="stylus">
.product-card__type
    position: relative
    color: white
    padding: 8px

.product-card__tag
    position: absolute
    top: 0px
    color: white

.product-card__buffet-type
    background: $blue-color
    color: white

.product-card__reservation-type
    background: $green-color
    color: white

.product-card__full-price
    font-size: 12px

.product-card__price
    font-size: 14px
    font-weight: 500

.product-card__type-addional-info
    padding: 0 0 0 8px

.product-card__tag__full
    background: $primary-color

.product-card__tag__canceled
    color: $disable-color
    background: $disable-bgcolor
</style>
