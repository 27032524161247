<template>
    <div>
        <VModal
            v-model="visibleCreateForm"
            title="สร้างการโปรโมท">
            <slot name="createForm" v-bind="{ prefillData,onCreateEntityCalendar }" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            :after-close="clearSelectedEntityId"
            title="แก้ไขการโปรโมท">
            <slot name="updateForm" v-bind="{ selectedEntityId,onCreateEntityCalendar,closeUpdateForm }" />
        </VModal>
        <div class="calendar__action">
            <a-button type="primary" @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <div class="calendar__content">
            <VCalendarView
                v-model="activeViewRef"
                :loading="loading"
                :split-days="splitDays"
                :on-view-change="onViewChange"
                :on-click-event="onClickEvent"
                :on-drag-to-create="onDragToCreateEvent"
                :events="events" />
        </div>
    </div>
</template>
<script>
import VCalendarView from '@/components/VCalendarView.vue'
import MixinCalendar from '@/mixins/MixinCalendar'
import { rootActions, Getters } from '@/store/types'
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'
import VModal from '@/components/VModal.vue'
import MixinCreateFormModal from '@/mixins/MixinCreateFormModal'
import { searchProduct } from '@/utils/UtilsProduct'
import { EventType, isStadiumUser, ProductType } from '@/utils/enum'
import moment from 'moment'
import { isEmptyArray } from '@/utils/utils'

export default {
    components: {
        VCalendarView,
        VModal,
    },
    mixins: [MixinCalendar, MixinUpdateFormModal, MixinCreateFormModal],
    data () {
        return {
            splitDaysStyles: [
                { color: 'blue', class: 'split1' },
                { color: 'green', class: 'split2' },
                { color: 'orange', class: 'split3' },
                { color: 'red', class: 'split4' },
            ],
        }
    },
    computed: {
        events () {
            const result = [...this.entities, ...this.newEntities].map((e) => ({
                id: e.id,
                start: e.start.format('YYYY-MM-DD HH:mm'),
                end: e.end.format('YYYY-MM-DD HH:mm'),
                noCheckedOutItem: e.noCheckedOutItem,
                totalImportToStock: e.totalImportToStock,
                noSoldItem: e.noSoldItem,
                title: this.field(e.fieldId).name,
                class: this.getClassOfProduct(e),
                split: e.fieldId,
            }))
            return isEmptyArray(result) ? [] : result
        },
        fields () {
            if (isStadiumUser(this.$store.state.auth.me)) {
                return this.$store.getters[Getters.stadium.GET_FIELDS_BY_STADIUM_ID](this.$store.state.stadium.id)
            }
            return this.events.map((event) => this.$store.getters[Getters.stadium.GET_FIELD](event.split))
        },
        splitDays () {
            if (this.activeView !== 'day') return []
            return this.fields.map((field, index) => ({
                id: field.id,
                label: field.name,
                hide: false,
                ...this.splitDaysStyles[index % 4],
            }))
        },
    },
    methods: {
        field (fieldId) {
            if (typeof this.$store.getters[Getters.stadium.GET_FIELD](fieldId) === 'undefined') return {}
            return this.$store.getters[Getters.stadium.GET_FIELD](fieldId)
        },
        async fetchEntitiesOnTimeline () {
            const { start, end } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_MY_PRODUCTS, {
                    isPast: this.isPast, page: 1, size: 200, start, end,
                },
            )
            if (this.activeView === 'day') await this.$store.dispatch(rootActions.LOAD_FIELDS, { page: 1, size: 30 })
            return res
        },
        onViewChange (attrs) {
            this.start = attrs.firstCellDate ? attrs.firstCellDate : attrs.startDate
            this.end = attrs.lastCellDate ? attrs.lastCellDate : attrs.endDate
        },
        getAllEntities () {
            return this.$store.state.products.products
        },
        onClickEvent (event) {
            this.openUpdateForm(event.id)
        },
        search (query) {
            return searchProduct(this.entities, query)
        },
        getClassOfProduct (product) {
            if (product.eventType === EventType.RESERVATION
                || product.type === ProductType.RESERVE) return 'reservation'
            if (product.eventType === EventType.BUFFET) return 'buffet'
            if (product.eventType === EventType.TOURNAMENT) return 'tournament'
            return 'party-event'
        },
        onDragToCreateEvent (obj) {
            const { start, end, split } = obj
            this.prefillData = { start: moment(start), end: moment(end), ...split ? { fieldId: split } : {} }
            this.openCreateForm()
        },
    },
}
</script>

<style lang="stylus">
.vuecal__event
    border: 1px solid #fff
    color: #fff
    background-color: alpha($yellow-color, 0.7);
    &.buffet
        background-color: $yellow-color;
    &.reservation
        background-color: $green-color;
    &.tournament
        background-color: $tournament-color;
    &.party-event
        background-color: $blue-color;
.calendar__action
    float: right
.calendar__content
    clear both
    // height: 900px
// .vuecal__cell-split
//     border-color: rgba(221, 238, 255, 0.5);
// .vuecal__cell-split .split-label {color: rgba(0, 0, 0, 0.1);font-size: 26px;}
.vuecal .day-split-header {font-size: 12px;}
.vuecal__body .split1 {background-color: rgba(226, 242, 253, 0.7);}
.vuecal__body .split2 {background-color: rgba(232, 245, 233, 0.7);}
.vuecal__body .split3 {background-color: rgba(255, 243, 224, 0.7);}
.vuecal__body .split4 {background-color: rgba(255, 235, 238, 0.7);}
</style>
