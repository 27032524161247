import EntitiesPage from '@/mixins/EntitiesPage'
import { Actions } from '@/store/types'

export default {
    mixins: [EntitiesPage],
    data () {
        return {
            columns: [
                {
                    title: 'จ่ายเงินแล้ว',
                    dataIndex: 'isPaid',
                    scopedSlots: { customRender: 'isPaid' },
                    width: 72,
                    align: 'center',
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'numberOfPeople',
                    scopedSlots: { customRender: 'numberOfPeople' },
                    width: 96,
                },
                {
                    title: 'ประเภทสมาชิก',
                    dataIndex: 'stadiumMembers[0]',
                    scopedSlots: { customRender: 'memberType' },
                },
                {
                    title: 'ชื่อจริง',
                    dataIndex: 'firstName',
                    scopedSlots: { customRender: 'text' },
                    // width: 120,
                },
                {
                    title: 'นามสกุล',
                    dataIndex: 'lastName',
                    scopedSlots: { customRender: 'text' },
                    // width: 120,
                },
                // {
                //     title: 'ชื่อเล่น',
                //     dataIndex: 'nickName',
                //     width: 120,
                //     scopedSlots: { customRender: 'text' },
                // },
                {
                    title: 'เบอร์โทร',
                    dataIndex: 'tel',
                    width: 120,
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'อีเมล(Email)',
                    dataIndex: 'email',
                    width: 120,
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'จดบันทึก (Note)',
                    dataIndex: 'note',
                    width: 180,
                    // fixed: 'right',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'การดำเนินการ (Operation)',
                    dataIndex: 'operation',
                    width: 160,
                    fixed: 'right',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
        }
    },
    methods: {
        getTextColumns () {
            return this.columns.filter((item) => item.scopedSlots.customRender !== 'operation' && item.scopedSlots.customRender !== 'id')
        },
        getMaxPeople () {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: MixinTableLocalCustomers] Please overide getMaxPeople() method.'))
            }
        },
        onClickCreate () {
            if (this.entities.length >= this.getMaxPeople()) {
                this.$store.dispatch(
                    Actions.message.SHOW_ERROR_MSG,
                    {
                        caller: 'ProductLocalCustomersFormItems',
                        msg: 'จำนวนคนเต็มแล้ว',
                    },
                )
            } else {
                this.openCreateForm()
            }
        },
    },
}
