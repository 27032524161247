var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างการโปรโมท" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _vm._t("createForm", null, null, {
            prefillData: _vm.prefillData,
            onCreateEntityCalendar: _vm.onCreateEntityCalendar
          })
        ],
        2
      ),
      _c(
        "VModal",
        {
          attrs: {
            "after-close": _vm.clearSelectedEntityId,
            title: "แก้ไขการโปรโมท"
          },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _vm._t("updateForm", null, null, {
            selectedEntityId: _vm.selectedEntityId,
            onCreateEntityCalendar: _vm.onCreateEntityCalendar,
            closeUpdateForm: _vm.closeUpdateForm
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "calendar__action" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.openCreateForm } },
            [_vm._v("\n            สร้าง\n        ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "calendar__content" },
        [
          _c("VCalendarView", {
            attrs: {
              loading: _vm.loading,
              "split-days": _vm.splitDays,
              "on-view-change": _vm.onViewChange,
              "on-click-event": _vm.onClickEvent,
              "on-drag-to-create": _vm.onDragToCreateEvent,
              events: _vm.events
            },
            model: {
              value: _vm.activeViewRef,
              callback: function($$v) {
                _vm.activeViewRef = $$v
              },
              expression: "activeViewRef"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }