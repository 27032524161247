var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "แก้ไขการโปรโมท" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("StadiumBoostUpdateForm", {
            attrs: {
              "disable-update": _vm.disableUpdate,
              "boost-id": _vm.product.id,
              data: _vm.product
            },
            on: {
              duplicate: _vm.handleCreateProduct,
              delete: _vm.closeUpdateForm,
              success: _vm.closeUpdateForm
            }
          })
        ],
        1
      ),
      _c(
        "VCard",
        {
          attrs: {
            disabled: _vm.isCanceled,
            avartar: _vm.avatar,
            title: _vm.title,
            "cover-photo-url": _vm.coverPhotoUrl,
            description: _vm.product.desc ? _vm.product.desc : "-",
            "cover-photo-ratio": _vm.ImageSize.w / _vm.ImageSize.h,
            "enable-delete": true
          },
          on: { delete: _vm.deleteProduct },
          nativeOn: {
            click: function($event) {
              return function() {
                return _vm.enableClickToOpenUpdateForm
                  ? _vm.openUpdateForm()
                  : null
              }.apply(null, arguments)
            }
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$slots, function(index, name) {
                return {
                  key: name,
                  fn: function() {
                    return [_vm._t(name)]
                  },
                  proxy: true
                }
              })
            ],
            null,
            true
          )
        },
        [
          _c("template", { slot: "cover" }, [
            _c(
              "div",
              { staticClass: "product-card__tag" },
              [
                _vm.isCancel
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "product-card__type product-card__tag__canceled"
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("a-icon", {
                              attrs: {
                                type: _vm.isCanceling ? "loading" : "stop"
                              }
                            }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.isCanceling
                                    ? "กำลังยกเลิก"
                                    : _vm.isCanceled
                                    ? "ยกเลิกแล้ว"
                                    : "ยกเลิก"
                                ) +
                                "\n                    "
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c("ProductTag", { attrs: { product: _vm.product } }),
                _vm.product.isReserveLimitReached
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "product-card__type product-card__tag__full"
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("a-icon", { attrs: { type: "lock" } }),
                            _vm._v(
                              "\n                        จองเต็มแล้ว\n                    "
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.product.isSoldOut
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "product-card__type product-card__tag__full"
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("a-icon", { attrs: { type: "check" } }),
                            _vm._v(
                              "\n                        จ่ายครบแล้ว\n                    "
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "template",
            { slot: "overlayBottomCover" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("getStartEndPrettyString")(_vm.product)) +
                  "\n            "
              ),
              _vm.defaultVariant
                ? [
                    _vm.product.eventType === _vm.ProductType.BUFFET
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.defaultVariant.minPrice
                                )
                              ) +
                              "\n                    /คน\n                "
                          )
                        ])
                      : _c("span", { staticClass: "product-card__price" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.defaultVariant.minPrice
                                )
                              ) +
                              "  /\n                    "
                          ),
                          _c("s", { staticClass: "product-card__full-price" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    _vm.defaultVariant.fullPrice
                                  )
                                ) +
                                "\n                    "
                            )
                          ])
                        ])
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }