import MixinEntitiesLazyLoad from '@/mixins/MixinEntitiesLazyLoad'
import { isEmpty, isEqual } from '@/utils/utils'

export default {
    model: {
        prop: 'activeView',
        event: 'update:activeView',
    },
    props: {
        activeView: {
            type: String,
            default: null,
        },
    },
    mixins: [MixinEntitiesLazyLoad],
    data () {
        return {
            start: null,
            end: null,
            isResetOnLoadMore: true,
            // This value is use instead of active view prop when prop is null
            defaultActiveView: 'month',
        }
    },
    computed: {
        startEndDateTime () {
            return {
                start: this.start,
                end: this.end,
            }
        },
        // we can't mutate prop variable so we create computed value for mutate propose
        activeViewRef: {
            get () { return this.activeView ? this.activeView : this.defaultActiveView },
            set (activeView) {
                if (this.activeView) {
                    this.$emit('update:activeView', activeView)
                } else {
                    this.defaultActiveView = activeView
                }
            },
        },
    },
    watch: {
        startEndDateTime: {
            handler (newValue, oldValue) {
                // Note: `newValue` will be equal to `oldValue` here
                // on nested mutations as long as the object itself
                // hasn't been replaced.
                const { start, end } = this
                if (isEmpty(start) || isEmpty(end)) return null
                if (isEqual(newValue, oldValue)) return newValue
                this.onFetchEntities()
                return newValue
            },
            deep: true,
        },
        // sometime computed setter not trigger
        activeViewRef: {
            handler (newVal) {
                this.$emit('update:activeView', newVal)
            },
            deep: true,
        },
    },
    methods: {
        onCreatedComponent () {
            const { start, end } = this
            if (isEmpty(start) || isEmpty(end)) return
            this.onFetchEntities()
        },
        async fetchEntitiesOnTimeline () {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins: EntitiesWithLazyLoad] Please overide fetchEntitiesOnTimeline() method.'))
            }
        },
        async fetchEntityWithLazyLoad () {
            return this.fetchEntitiesOnTimeline()
        },
        onCreateEntityCalendar (entity) {
            return this.onCreateEntityWithLazyLoad(entity)
        },
    },
}
