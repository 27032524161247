<template>
    <VForm
        v-slot="{ form }"
        class="reset-password-form"
        :on-submit-async="handleSubmit">
        <a-alert
            v-if="responseMsg"
            class="reset-password-form__info-msg"
            :type="messageType"
            :message="responseMsg"
            banner="banner" />
        <a-form-item
            label="รหัสผ่านปัจจุบัน">
            <a-input
                v-decorator="[
                    'currentPassword',
                    { rules: [ { required: true,
                                 message: 'กรุณาใส่ รหัสผ่านที่ต้องการสร้างใหม่' } ] } ]"
                type="password"
                placeholder="รหัสผ่านปัจจุบัน">
                <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0,0,0,.25);" />
            </a-input>
        </a-form-item>
        <PasswordResetFormItem :form="form" />
    </VForm>
</template>
<script>
import { rootActions } from '@/store/types'
import PasswordResetFormItem from '@/components/PasswordResetFormItem.vue'
import VForm from '@/components/VForm.vue'

export default {
    components: {
        PasswordResetFormItem,
        VForm,
    },
    props: {
        token: {
            type: String,
            default: '',
        },
        userId: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            responseMsg: null,
            status: null,
            formLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 12 },
            },
        }
    },
    computed: {
        messageType () {
            if (this.status === 'success') return 'success'
            if (this.status === 'error') return 'error'
            return 'warning'
        },
    },
    watch: {
        status (newStatus) {
            if (newStatus === 'success') {
                this.showSuccessModal()
            }
        },
    },
    methods: {
        async handleSubmit (values) {
            const res = await this.$store.dispatch(
                rootActions.CHANGE_PASSWORD,
                {
                    currentPassword: values.currentPassword,
                    newPassword: values.password,
                },
            )
            if (res.isSuccess()) {
                await this.$store.dispatch(
                    rootActions.LOGOUT,
                )
            }
            this.status = res.status
            this.responseMsg = res.msg
            return res
        },
        showSuccessModal () {
            const router = this.$router
            this.$success({
                title: 'สร้างรหัสผ่านใหม่สำเร็จ',
                content: (
                    <div>
                        <p>คุณสามารถเข้าระบบด้วยรหัสผ่านใหม่ได้แล้วค่ะ</p>
                    </div>
                ),
                centered: true,
                onOk () {
                    router.push({ name: 'login' })
                },
            })
        },
    },
}
</script>
<style lang="stylus">
.reset-password-form
    margin: 0 auto

.reset-password-form__info-msg
    margin-bottom: 16px

.reset-password-form__login
    margin-left: 16px
</style>
