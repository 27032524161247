<template>
    <VPage>
        <h3>รูปแบบ การแสดงผล</h3>
        <a-radio-group
            :value="selectedViewMode"
            button-style="solid"
            @change="handleViewModeChange">
            <a-radio-button :value="ViewMode.GRID">
                <a-icon type="table" />
            </a-radio-button>
            <a-radio-button :value="ViewMode.CALENDAR">
                <a-icon type="calendar" />
            </a-radio-button>
        </a-radio-group>
        <slot v-if="selectedViewMode === ViewMode.CALENDAR" name="calendarEvent" />
        <div v-else class="stadium-boosts">
            <a-tabs default-active-key="1">
                <a-tab-pane key="1">
                    <span slot="tab">
                        <a-icon type="double-right" />
                        กำลังจะมาถึง
                    </span>
                    <slot name="incomingEvent" />
                </a-tab-pane>
                <a-tab-pane key="2">
                    <span slot="tab">
                        <a-icon type="history" />
                        ประวัติ
                    </span>
                    <slot name="historyEvent" />
                </a-tab-pane>
            </a-tabs>
        </div>
    </VPage>
</template>
<script>
import VPage from '@/components/VPage.vue'
import { ViewMode } from '@/utils/enum'

export default {
    components: {
        VPage,
    },
    data () {
        return {
            selectedViewMode: ViewMode.GRID,
            ViewMode,
        }
    },
    methods: {
        handleViewModeChange (e) {
            this.selectedViewMode = e.target.value
        },
    },
}
</script>
