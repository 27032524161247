var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ProductLocalCustomerCreateForm", {
    attrs: { "max-people": _vm.boost.totalImportToStock },
    on: { success: _vm.submit }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }