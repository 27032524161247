<template>
    <VForm
        v-slot="{ form }"
        class="customer-create-form"
        :form="form"
        :on-submit-async="submit"
        layout="horizontal"
        @success="(value)=>this.$emit('success',value)">
        <StadiumBoostLocalCustomersCreateAndUpdateFormItems
            :form="form"
            :can-buy-multiple="canBuyMultiple"
            action-type="create" />
    </VForm>
</template>
<script>
import StadiumBoostLocalCustomersCreateAndUpdateFormItems from '@/components/StadiumBoostLocalCustomersCreateAndUpdateFormItems.vue'
import Form from '@/mixins/Form'
import VForm from '@/components/VForm.vue'

export default {
    components: {
        StadiumBoostLocalCustomersCreateAndUpdateFormItems,
        VForm,
    },
    mixins: [Form],
    props: {
        maxPeople: {
            type: Number,
            required: true,
        },
    },
    computed: {
        canBuyMultiple () {
            return this.maxPeople > 1
        },
    },
    methods: {
        async submit (values) {
            return values
        },
    },
}
</script>
