var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "สนาม"
          }
        },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "fieldId",
                    {
                      rules: [
                        { required: true, message: "เลือกสนามที่ต้องการโปรโมท" }
                      ]
                    }
                  ],
                  expression:
                    "[ 'fieldId' ,\n                           { rules:\n                               [\n                                   { required: true,\n                                     message: 'เลือกสนามที่ต้องการโปรโมท', } ] } ]"
                }
              ],
              staticStyle: { width: "240px" },
              attrs: {
                disabled: _vm.disabled,
                "show-search": "",
                placeholder: "เลือกสนาม",
                "option-filter-prop": "children",
                "filter-option": _vm.filterOption,
                loading: _vm.isLoadFields
              },
              on: { change: _vm.onFieldChange, focus: _vm.loadFields }
            },
            _vm._l(_vm.fields, function(field) {
              return _c(
                "a-select-option",
                { key: field.id, attrs: { value: field.id } },
                [
                  _vm._v(
                    "\n                " + _vm._s(field.name) + "\n            "
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm.isReserveTypeOnly
        ? _c(
            "a-form-item",
            {
              attrs: {
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 },
                label: "ประเภท"
              }
            },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "type",
                        {
                          initialValue: _vm.ProductType.RESERVE
                        }
                      ],
                      expression:
                        "\n                [ 'type',\n                  {\n                      initialValue: ProductType.RESERVE\n                  }\n                ]"
                    }
                  ],
                  attrs: { disabled: _vm.type !== "create" }
                },
                [
                  _c("a-radio", { attrs: { value: _vm.ProductType.RESERVE } }, [
                    _vm._v(
                      "\n                จองปกติ (ซื้อแล้วได้ทั้งสนาม)\n            "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "a-form-item",
            {
              attrs: {
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 },
                label: "ประเภทโปรโมชั่น"
              }
            },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "eventType",
                        {
                          initialValue: _vm.EventType.RESERVATION
                        }
                      ],
                      expression:
                        "\n                [ 'eventType',\n                  {\n                      initialValue: EventType.RESERVATION\n                  }\n                ]"
                    }
                  ],
                  attrs: { disabled: _vm.type !== "create" }
                },
                [
                  _c(
                    "a-radio",
                    { attrs: { value: _vm.EventType.RESERVATION } },
                    [
                      _vm._v(
                        "\n                จองปกติ (ซื้อแล้วได้ทั้งสนาม)\n            "
                      )
                    ]
                  ),
                  _c("a-radio", { attrs: { value: _vm.EventType.BUFFET } }, [
                    _vm._v(
                      "\n                บุฟเฟ่ (ซื้อเป็นรายคนร่วมกันเล่น)\n            "
                    )
                  ]),
                  _c("a-radio", { attrs: { value: _vm.EventType.TRAINING } }, [
                    _vm._v("\n                คอร์สเรียน\n            ")
                  ]),
                  _c(
                    "a-radio",
                    { attrs: { value: _vm.EventType.TOURNAMENT } },
                    [_vm._v("\n                การแข่งขัน\n            ")]
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "คำบรรยายเบื้องต้น"
          }
        },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["desc"],
                expression: "[ 'desc' ]"
              }
            ],
            attrs: {
              placeholder:
                "เช่น ความพิเศษของโปรโมชั่น(เช่น 1เดือนมีครั้ง) , เงื่อนไขการใช้งาน",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      ),
      _c("VFormItemRangeDateTime", {
        attrs: { data: _vm.data, form: _vm.form },
        on: {
          startDateTimeChange: _vm.onStartDateTimeChange,
          endDateTimeChange: _vm.onEndDateTimeChange
        }
      }),
      _vm.estimatePrice
        ? _c(
            "a-form-item",
            {
              attrs: { "label-col": { span: 4 }, "wrapper-col": { span: 12 } }
            },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v("ราคาประเมิน  \n            "),
                  _c(
                    "a-tooltip",
                    { attrs: { title: "คำนวนจาก ตั้งค่าสนาม->ราคา" } },
                    [_c("a-icon", { attrs: { type: "question-circle-o" } })],
                    1
                  ),
                  _vm._v(" \n        ")
                ],
                1
              ),
              _c("a-input-number", {
                staticClass: "estimatePrice",
                attrs: { value: _vm.estimatePrice, min: 0, disabled: true }
              }),
              _vm._v(" บาท "),
              _c("strong", [
                _vm._v("*เป็นราคาอ้างอิงจากตารางราคา ช่วยในการตัดสินใจตั้งราคา")
              ]),
              _c("br"),
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.copyPriceToClipboard }
                },
                [
                  _c("a-icon", { attrs: { type: "copy" } }),
                  _vm._v("\n            คัดลอก\n        ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("VFormItemNumberWithBar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.canHaveMultiple(),
            expression: "canHaveMultiple()"
          }
        ],
        attrs: {
          "name-prop": "totalImportToStock",
          form: _vm.form,
          data: _vm.data,
          min: 1,
          label: "จำนวนสินค้าทั้งหมด",
          validator: _vm.validateMaxPeople,
          required: _vm.canHaveMultiple()
        }
      }),
      _c(
        "a-form-item",
        {
          attrs: {
            label: "ช่องทางการขาย",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "isPrivate",
                        {
                          initialValue: false,
                          rules: [
                            {
                              required: true,
                              message: "เลือกช่องทางการขายด้วยค่ะ"
                            }
                          ]
                        }
                      ],
                      expression:
                        "[ 'isPrivate' ,\n                               {\n                                   initialValue: false,\n                                   rules:\n                                       [\n                                           { required: true,\n                                             message: 'เลือกช่องทางการขายด้วยค่ะ', } ] } ]"
                    }
                  ],
                  attrs: { "button-style": "solid" }
                },
                [
                  _c("a-radio-button", { attrs: { value: false } }, [
                    _vm._v(
                      "\n                    แอป Lenkila และ จองตรง\n                "
                    )
                  ]),
                  _c("a-radio-button", { attrs: { value: true } }, [
                    _vm._v(
                      "\n                    จองตรงเท่านั้น\n                "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      !_vm.form.getFieldValue("isPrivate")
        ? _c("ProductVariantFormItems", {
            attrs: {
              form: _vm.form,
              data: _vm.data,
              "is-update-form": _vm.isUpdateForm
            }
          })
        : _vm._e(),
      !_vm.isUpdateForm && _vm.form.getFieldValue("isPrivate")
        ? _c("ProductLocalCustomersFormItems", {
            attrs: {
              form: _vm.form,
              data: _vm.data,
              "max-people": _vm.form.getFieldValue("totalImportToStock")
                ? _vm.form.getFieldValue("totalImportToStock")
                : 1
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }