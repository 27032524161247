<template>
    <div
        class="boost-buffet-or-party-event__tag"
        :class="{
            'boost-buffet-or-party-event__tag--buffet': isBuffet,
            'boost-buffet-or-party-event__tag--tournament': isTournament }">
        {{ product | getEventTypePrettyString }}
        <span class="boost-buffet-or-party-event__info">
            {{ product.noCheckedOutItem ? product.noCheckedOutItem : 0 }}
            /{{ product.totalImportToStock }}
            <a-icon type="team" />
        </span>
    </div>
</template>
<script>
import { EventType, ProductType } from '@/utils/enum'

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ProductType,
        }
    },
    computed: {
        isBuffet () {
            return this.product.eventType === EventType.BUFFET
        },
        isTournament () {
            return this.product.eventType === EventType.TOURNAMENT
        },
    },
}
</script>
<style lang="stylus">
.boost-buffet-or-party-event__tag
    position: relative
    color: white
    padding: 8px
    background: $blue-color

.boost-buffet-or-party-event__tag.boost-buffet-or-party-event__tag--buffet
    background: $yellow-color

.boost-buffet-or-party-event__tag.boost-buffet-or-party-event__tag--tournament
    background: $tournament-color

.boost-buffet-or-party-event__info
    padding: 0 0 0 8px

</style>
