var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: "สิทธิที่ใช้ในการซื้อ",
            width: "50%",
            centered: "centered",
            footer: null,
            "destroy-on-close": ""
          },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("ManageTxItemMemberForm", {
            attrs: {
              "product-id": _vm.selectedEntityId,
              "tx-item": _vm.txItem
            },
            on: { success: _vm.onUpdateMemberSuccess }
          })
        ],
        1
      ),
      _vm.model
        ? _c(
            "div",
            {
              staticClass: "customers__member-type",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.openUpdateForm(_vm.productId)
                }
              }
            },
            [
              _c(
                "a",
                {
                  staticClass: "customers__member-type-action",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openUpdateForm(_vm.productId)
                    }
                  }
                },
                [_c("a-icon", { attrs: { type: "form" } })],
                1
              ),
              _c("div", {
                staticClass: "customers__member-type-color",
                style: { backgroundColor: _vm.model.color, maxWidth: "72px" }
              }),
              _c("span", { staticClass: "customers__member-type-name" }, [
                _vm._v("  " + _vm._s(_vm.model.name) + " ")
              ])
            ]
          )
        : _c(
            "div",
            {
              staticClass: "customers__member-type",
              style: { textAlign: "center" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.openUpdateForm(_vm.productId)
                }
              }
            },
            [_c("a", { attrs: { href: "javascript:;" } }, [_vm._v("เพิ่ม")])]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }