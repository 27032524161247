<template>
    <div class="member-type-details">
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Color">
            {{ memberType.color }}
            <div
                class="member-type-details__color-preview"
                :style="{ backgroundColor: memberType.color }" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Name">
            {{ memberType.name }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Description">
            {{ memberType.description }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Type">
            {{ memberType.type }}
        </a-form-item>
        <a-form-item
            v-show="memberType.type === 'yearly'"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Year Amount">
            {{ expiredInYear }}
        </a-form-item>
        <a-form-item
            v-show="memberType.type === 'monthly'"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Month Amount">
            {{ expiredInMonth }}
        </a-form-item>
        <a-form-item
            v-show="memberType.type !== 'permanant'"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Day Amount">
            {{ memberType.expiredInDay }}
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="Price">
            {{ memberType.price }}
        </a-form-item>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
    computed: {
        memberType () {
            return this.data
        },
        expiredInYear () {
            return this.memberType.expiredInDay / 365
        },
        expiredInMonth () {
            return this.memberType.expiredInDay / 30
        },
    },
}
</script>
<style lang="stylus">
.member-type-details
    label
        font-weight bold
.member-type-details__color-preview
    height 32px
    width 64px
    vertical-align middle
    display inline-block
</style>
