<template>
    <a-form
        class="member-types-update-form"
        :form="form"
        layout="horizontal"
        @submit="handleSubmit">
        <MemberTypeCreateAndUpdateFormItems :form="form" :data="memberType" />
        <a-form-item
            :wrapper-col="{ span: 12, offset: 4 }"
            :style="{ marginBottom: 0 }">
            <a-button
                class="member-types-update-form__save-button"
                type="primary"
                html-type="submit"
                :loading="isProcessing">
                Save
            </a-button>
        </a-form-item>
    </a-form>
</template>
<script>
import MemberTypeCreateAndUpdateFormItems from '@/components/MemberTypeCreateAndUpdateFormItems.vue'
import { rootActions, Getters } from '@/store/types'
import Form from '@/mixins/Form'

export default {
    components: {
        MemberTypeCreateAndUpdateFormItems,
    },
    mixins: [Form],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        memberType () {
            return this.$store.getters[Getters.customers.GET_MEMBER_TYPE](this.id)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_MEMBER_TYPE, { id: this.id, ...values },
            )
            return res
        },
    },
}
</script>
